import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_API + "api/arvatorecurring";

export async function getInvoices(subId) {
  const { data } = await http.get(apiEndpoint + "/subscription/" + subId + "/invoices");
  return data;
}

export async function getInvoiceTimeline(subId, invoiceId) {
  const { data } = await http.get(apiEndpoint + "/subscription/" + subId + "/invoices/" + invoiceId + "/timeline");
  return data;
}

export async function creditInvoice(subId, invoiceId) {
  await http.post(apiEndpoint + "/subscription/" + subId + "/invoices/" + invoiceId + "/credit");
}

export async function getSubscription(subId) {
  const { data } = await http.get(apiEndpoint + "/subscription/" + subId);
  return data;
}

export async function getSubscriptionTimeline(subId) {
  const { data } = await http.get(apiEndpoint + "/subscription/" + subId + "/timeline");
  return data;
}

export async function cancelSubscription(subId) {
  await http.post(apiEndpoint + "/subscription/" + subId + "/cancel");
}

export async function reactivateSubscription(subId) {
  await http.post(apiEndpoint + "/subscription/" + subId + "/reactivate");
}

export async function terminateSubscription(subId) {
  await http.post(apiEndpoint + "/subscription/" + subId + "/terminate");
}
