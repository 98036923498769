import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import PageHeader from "./common/PageHeader";
import authService from "../services/authService";
import { getByrdStock } from "../services/byrdService";

const ByrdStock = () => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let stock = await getByrdStock();
      stock.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });
      setStocks(stock);
      setLoading(false);
    })();
  }, []);

  if (!authService.hasRole("admin")) {
    return "Unauthorized";
  }

  if (!stocks) {
    return null;
  }

  const data = stocks;
  const dataLength = stocks.length;
  const totalLength = stocks.length;

  const pageName = "Byrd Stock";
  const headers = ["Name", "Description", "Sku", "Amount(reserved)"];

  return (
    <React.Fragment>
      <PageHeader pageName={pageName} sorted={data} count={dataLength} totalCount={totalLength} loading={loading} />
      {!loading && (
        <Row>
          <Col className="col-sm-12">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {headers.map((header, index) => {
                    return <th key={index}>{header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>{row.sku}</td>
                      <td>
                        {row.physicalStock}({row.reservedStock})
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default ByrdStock;
