import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/reports";

export async function getReports() {
  const { data } = await http.get(apiEndpoint);
  return data;
}

export async function getReportsUser(username) {
  const { data } = await http.post(apiEndpoint, username);
  return data;
}

export async function getChurn() {
  const { data } = await http.get(apiEndpoint + "/churn");
  return data;
}
