import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/orders";
const klarnaEndpoint = env.REACT_APP_API + "api/klarna";
const afterpayEndpoint = env.REACT_APP_API + "api/afterpay";
const stripeEndpoint = env.REACT_APP_API + "api/stripe";

export async function getOrders() {
  const { data } = await http.get(apiEndpoint);
  return data;
}

export async function createOrder(item) {
  const { data } = await http.post(apiEndpoint, item);
  return data;
}

export async function deleteOrder(orderId) {
  const { data } = await http.delete(apiEndpoint + "/" + orderId);
  return data;
}

export async function getOrdersCount() {
  const { data } = await http.get(apiEndpoint + "/count");
  return data;
}

export async function getOrder(orderId) {
  const { data } = await http.get(apiEndpoint + "/" + orderId);
  return data;
}

export async function getInvoiceById(invoiceId) {
  const { data } = await http.get(apiEndpoint + "/invoice/" + invoiceId, {
    responseType: "blob",
  });
  return data;
}

export async function getCreditById(invoiceId) {
  const { data } = await http.get(apiEndpoint + "/credit/" + invoiceId, {
    responseType: "blob",
  });
  return data;
}

export async function getOrdersByCustomerId(customerId) {
  const { data } = await http.get(apiEndpoint + "/customer/" + customerId);
  return data;
}

export async function updateOrder(item) {
  const { data } = await http.put(apiEndpoint, item);
  return data;
}

export async function getOrderById(orderId) {
  const { data } = await http.get(apiEndpoint + "/" + orderId);
  return data;
}

export async function creditOrder(order, subId) {
  order.subId = subId;
  const { data } = await http.post(apiEndpoint + "/visma/credit", order);
  return data;
}

export async function creditArvatoOrder(order) {
  const { data } = await http.post(apiEndpoint + "/arvato/credit", order);
  return data;
}

export async function refundKlarnaOrder(order, subId) {
  order.subId = subId;
  const { data } = await http.post(klarnaEndpoint + "/order/refund", order);
  return data;
}

export async function cancelKlarnaOrder(order, subId) {
  order.subId = subId;
  const { data } = await http.post(klarnaEndpoint + "/order/cancel", order);
  return data;
}

export async function refundAfterpayOrder(order, subId) {
  order.subId = subId;
  const { data } = await http.post(afterpayEndpoint + "/refund", order);
  return data;
}

export async function refundStripeOrder(order, subId) {
  order.subId = subId;
  const { data } = await http.post(stripeEndpoint + "/refund-order", order);
  return data;
}
