import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { getRefresh } from "../services/userService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import socketIOClient from "socket.io-client";
const env = runtimeEnv();

class ClickCount extends Component {
  state = {
    users: [],
  };

  async componentDidMount() {
    const socket = socketIOClient(env.REACT_APP_API, {
      path: "/socket.io-client",
      transports: ["websocket"],
    });
    socket.on("refresh", (result) => {
      this.setState({ users: result });
    });
    const users = await getRefresh();
    this.setState({ users });
  }

  render() {
    return (
      <React.Fragment>
        <Row style={{ textAlign: "center" }}>
          <Col>
            <h1>Refresh leaderboard</h1>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12">
            {this.state.users.map((row, index) => {
              return (
                <h4 key={index}>
                  {row.name}: {row.refreshCount}
                </h4>
              );
            })}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ClickCount;
