import React, { useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography, Annotation } from "react-simple-maps";
import { feature } from "topojson-client";
import europeMap from "./europe.topo.json";
import styles from "./EuropeMap.module.css";
import date from "date-and-time";
import Clock from "./Clock";

function createScaledCountryData(inputData) {
  const maxValue = Math.max(...Object.values(inputData));
  const scaledData = {};
  for (const countryCode in inputData) {
    scaledData[countryCode] = inputData[countryCode] / maxValue;
  }

  return scaledData;
}

function makeMapCountryData(products) {
  let countryData = {
    NO: 0,
    SE: 0,
    DK: 0,
    DE: 0,
    NL: 0,
    BE: 0,
    AT: 0,
    CH: 0,
    FI: 0,
    PL: 0,
    CZ: 0,
    FR: 0,
    IE: 0,
    PT: 0,
  };

  for (let i = 0; i < products.length; i++) {
    const product = products[i];

    switch (product.country) {
      case "no":
        countryData["NO"] += product.ordersToday;
        break;
      case "se":
        countryData["SE"] += product.ordersToday;
        break;
      case "dk":
        countryData["DK"] += product.ordersToday;
        break;
      case "de":
        countryData["DE"] += product.ordersToday;
        break;
      case "at":
        countryData["AT"] += product.ordersToday;
        break;
      case "nl":
        countryData["NL"] += product.ordersToday;
        break;
      case "be":
        countryData["BE"] += product.ordersToday;
        break;
      case "ch":
        countryData["CH"] += product.ordersToday;
        break;
      case "fi":
        countryData["FI"] += product.ordersToday;
        break;
      case "pl":
        countryData["PL"] += product.ordersToday;
        break;
      case "cz":
        countryData["CZ"] += product.ordersToday;
        break;
      case "fr":
        countryData["FR"] += product.ordersToday;
        break;
      case "ie":
        countryData["IE"] += product.ordersToday;
        break;
      case "pt":
        countryData["PT"] += product.ordersToday;
        break;
    }
  }

  return countryData;
}
const getLabelPosition = (countryCode) => {
  const positions = {
    NO: [9.3, 61],
    SE: [15, 62],
    DK: [9, 56],
    DE: [10.5, 51],
    NL: [5.75, 52],
    BE: [4.3, 50.5],
    AT: [15, 47.3],
    CH: [8, 46.5],
    FI: [26, 63.5],
    PL: [19, 52],
    CZ: [14.5, 49.5],
    FR: [2, 47],
    IE: [-8, 53],
    PT: [-8, 38],
  };
  return positions[countryCode] || [0, 0];
};

const EuropeMap = ({ products }) => {
  const countryData = makeMapCountryData(products);
  const [prevSalesCount, setPrevSalesCount] = useState(0);

  useEffect(() => {
    const totalSales = Object.values(countryData).reduce((acc, curr) => acc + curr, 0);
    if (prevSalesCount < totalSales) {
      playSound();
      setPrevSalesCount(totalSales);
    }
  }, [products]);

  const playSound = () => {
    const audio = new Audio("/khaleeed.mp3");

    audio.play();
  };

  const countries = feature(europeMap, europeMap.objects.continent_Europe_subunits).features;
  const getColor = (countryCode) => {
    const scaledData = createScaledCountryData(countryData);
    const value = scaledData[countryCode];
    console.log(value);
    if (value === undefined || value === 0) {
      return "lightgray";
    }
    return `RGBA(0,101,80, ${value})`;
  };

  return (
    <div className={styles.outer}>
      <Clock />
      <h1 className={styles.maptitle}>Sales Today</h1>
      <h4> {date.format(new Date(), "DD-MM-YYYY")}</h4>
      <div className={styles.mapContainer}>
        <ComposableMap
          width={400}
          height={400}
          projectionConfig={{
            scale: 600,
            rotate: [20, 0, 0],
            center: [20, 50],
          }}
        >
          <Geographies geography={countries}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  className={styles.country}
                  fill={getColor(geo.id)}
                  stroke="#FFF"
                  style={{
                    default: {
                      outline: "none",
                      transition: "fill 0.5s", // Add a transition for a smooth color change
                    },
                    hover: { outline: "none" },
                    pressed: { outline: "none" },
                  }}
                />
              ))
            }
          </Geographies>
          {Object.keys(countryData).map((countryCode) => (
            <Annotation key={`label-${countryCode}`} subject={getLabelPosition(countryCode)} dx={0} dy={0}>
              <text className={styles.numberSales} y="2" fontSize={14} textAnchor="middle">
                {countryData[countryCode]}
              </text>
            </Annotation>
          ))}
        </ComposableMap>
      </div>
    </div>
  );
};

export default EuropeMap;
