import React, { Component } from "react";
import { ButtonGroup, Spinner } from "react-bootstrap";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

class Dialog extends Component {
  render() {
    return (
      <Modal isOpen={this.props.open} onAfterOpen={this.afterOpenModal} onRequestClose={this.props.close} style={customStyles}>
        <h2>{this.props.title}</h2>
        <div className="mb-3">{this.props.text}</div>
        {this.props.reasons && (
          <div className="form-group">
            <label htmlFor="reasons">Reason for unsub</label>
            <select className="form-control" value={this.props.reasons[this.props.reason]} onChange={this.props.handleDropdownChange} name="reasons">
              {this.props.reasons.map((value, index) => {
                return (
                  <option value={index} key={index}>
                    {value.reason}
                  </option>
                );
              })}
            </select>
            <input type="checkbox" checked={this.props.checkboxChecked} onChange={this.props.handleCheckboxChange} name="exempt" />{" "}
            <label htmlFor="exempt">Unsub immediately (doesn't consider deadline)</label>
          </div>
        )}
        {this.props.sms && <div>SMS FORM</div>}
        <ButtonGroup>
          <button className="btn btn-danger mr-3" onClick={this.props.doYes} disabled={this.props.loading}>
            {this.props.loading && <Spinner animation="border" size="sm" />}
            {this.props.yes}
          </button>
          <button className="btn btn-secondary" onClick={this.props.doNo} disabled={this.props.loading}>
            {this.props.no}
          </button>
        </ButtonGroup>
      </Modal>
    );
  }
}

export default Dialog;
