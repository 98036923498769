import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/users";

export async function postRefresh(user) {
  const { data } = await http.post(apiEndpoint + "/refresh", user);
  return data;
}

export async function getRefresh() {
  const { data } = await http.get(apiEndpoint + "/refresh");
  return data;
}

export async function getCountries(id) {
  const { data } = await http.post(apiEndpoint + "/countries", { id });
  return data;
}
