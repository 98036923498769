import { getCustomers, getTotalCustomersCount } from "../services/customerService";
import { getProductFilters } from "../services/productService";
import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  TablePagination,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  Autocomplete,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import date from "date-and-time";
import authService from "../services/authService";
import { getCountries } from "../services/userService";
import styled from "styled-components";
import { getFlag } from "../utils/countries";
import { useHistory, useLocation } from "react-router-dom";
import { SearchContext, SearchDispatchContext, searchReducer } from "./search.context";

const StyledTableCell = styled(TableCell)`
  border-right: 2px solid lightgrey;
  font-size: 16px !important;
`;

const CustomersMUI = (props) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(1);
  const [countries, setCountries] = useState([]);
  const [sources] = useState(["FACEBOOK", "GOOGLE", "ORION", "TELE", "OTHER", "TIKTOK"]);
  const [saleTypes] = useState(["All", "Subs", "Singles"]);
  const [filters, setFilters] = useState([]);
  const [sortField, setSortField] = useState(null); // Possible values: 'created', 'name', 'activeProducts'
  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' or 'desc'

  // active filters are maintained in a context wrapped around the entire application so that we remember the last search when we go back.
  const activeFilters = useContext(SearchContext);
  const dispatch = useContext(SearchDispatchContext);

  const fetchData = async () => {
    const { data } = await getCustomers({
      page: currentPage,
      pagination: pageSize,
      phrase: activeFilters.searchQuery,
      productId: activeFilters.product,
      country: activeFilters.country.id,
      source: activeFilters.source,
      saleType: activeFilters.saleType,
      sortField,
      sortDirection,
    });

    setData(data); // Adjust as necessary based on the actual data structure
    const { length } = await getTotalCustomersCount({
      phrase: activeFilters.searchQuery,
      productId: activeFilters.product,
      country: activeFilters.country.id,
      source: activeFilters.source,
      saleType: activeFilters.saleType,
    });
    setTotalRows(Math.ceil(length));
    let filterList = await getProductFilters(authService.getCurrentUser()._id);
    filterList.unshift("All");
    setFilters(filterList); // set the state
    let countries = await getCountries(authService.getCurrentUser()._id);
    countries.unshift({ code: "All", nameEng: "All" });
    setCountries(countries); // set the state
  };

  useEffect(() => {
    if (authService.hasRole(["admin", "support"])) {
      fetchData();
    }
  }, [currentPage, pageSize, sortField, sortDirection, activeFilters]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1); // reset to the first page
  };

  const [key, setKey] = useState(Math.random()); // Add this state

  const typedSearchQuery = useRef(""); // Using useRef to hold mutable values

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch({ type: "change", filter: "searchQuery", value: e.target.value });
      setCurrentPage(1);
    }
  };

  const handleSearchChange = (e) => {
    typedSearchQuery.current = e.target.value;
  };

  const handleCloseIconClick = () => {
    typedSearchQuery.current = "";
    dispatch({ type: "change", filter: "searchQuery", value: "" });
    dispatch({ type: "change", filter: "product", value: "All" });
    dispatch({ type: "change", filter: "country", value: "All" });
    dispatch({ type: "change", filter: "saleType", value: "All" });
    dispatch({ type: "change", filter: "source", value: "All" });
    setCurrentPage(1);
    setKey(Math.random()); // Force re-render TextField
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const editCustomer = (customer, newWindow) => {
    const host = window.location.protocol + "//" + window.location.host;
    const path = "/customers/" + customer._id;
    if (!newWindow) {
      props.history.push(path);
    } else {
      const url = host + path;
      window.open(url, "_blank");
    }
  };

  // Define headers based on the data schema
  const headers = [
    "Flag",
    { label: "Created", field: "created" },
    "Id",
    { label: "Name", field: "name" },
    { label: "Street", field: "street" },
    "PostCode",
    { label: "PostArea", field: "postarea" },
    "Country",
    { label: "Email", field: "email" },
    "Phone",
    "Active Subs",
    "Products",
    "Single Sales",
    "Personal ID",
  ];

  console.log("Active Filters", activeFilters);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Filter and Search */}
      <div style={{ margin: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              key={key} // Use a key to force re-render
              label="Search"
              variant="outlined"
              defaultValue={activeFilters.searchQuery && activeFilters.searchQuery.length > 0 ? activeFilters.searchQuery : typedSearchQuery.current} // use defaultValue instead of value
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleCloseIconClick}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              options={filters.map((series) => ({ id: series, label: series }))}
              renderInput={(params) => <TextField {...params} label="Product" />}
              value={activeFilters.product}
              onChange={(event, value) => {
                if (value) {
                  dispatch({ type: "change", filter: "product", value: value.id });
                } else {
                  dispatch({ type: "change", filter: "product", value: "All" });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              options={countries.map((country) => ({ id: country.code + "", label: country.nameEng }))}
              renderInput={(params) => <TextField {...params} label="Country" />}
              value={activeFilters.country}
              onChange={(event, value) => {
                if (value) {
                  dispatch({ type: "change", filter: "country", value: value });
                } else {
                  dispatch({ type: "change", filter: "country", value: "All" });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              options={saleTypes.map((source) => ({ id: source, label: source }))}
              renderInput={(params) => <TextField {...params} label="Sale Types" />}
              value={activeFilters.saleType}
              onChange={(event, value) => {
                if (value) {
                  dispatch({ type: "change", filter: "saleType", value: value.id });
                } else {
                  dispatch({ type: "change", filter: "saleType", value: "All" });
                }
              }}
            />
          </Grid>
          {authService.hasRole(["admin"]) && (
            <Grid item xs={12} md={3}>
              <Autocomplete
                fullWidth
                options={sources.map((source) => ({ id: source, label: source }))}
                renderInput={(params) => <TextField {...params} label="Sources" />}
                value={activeFilters.source}
                onChange={(event, value) => {
                  if (value) {
                    dispatch({ type: "change", filter: "source", value: value.id });
                  } else {
                    dispatch({ type: "change", filter: "source", value: "All" });
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>

      {/* Table */}
      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                {headers.map((header, index) => {
                  let label;
                  let field;
                  if (typeof header === "string") {
                    label = header;
                  } else {
                    label = header.label;
                    field = header.field;
                  }
                  return (
                    <StyledTableCell className="theme-table-head" key={index} style={{ fontWeight: "bold" }}>
                      {field ? (
                        <span onClick={() => handleSortChange(field)} style={{ cursor: "pointer" }}>
                          {label}
                          {sortField === field && (sortDirection === "asc" ? " ↑" : " ↓")}
                        </span>
                      ) : (
                        label
                      )}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((customer, index) => {
                let activeSubs = 0;
                let productText = "";
                for (const key in customer.subs) {
                  if (customer.subs[key].active) {
                    activeSubs++;
                    if (key === (customer.subs.length - 1).toString()) productText = productText + customer.subs[key].product.name;
                    else productText = productText + customer.subs[key].product.name + ", ";
                  }
                }
                let singleSales = 0;
                if (customer.orders && customer.orders.length >= 1) {
                  singleSales = customer.orders.length;
                }
                return (
                  <TableRow
                    key={customer._id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "rgba(0, 0, 0, 0.02)" : "rgba(0, 0, 0, 0.12)",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      const newWindow = e.ctrlKey || e.metaKey;
                      editCustomer(customer, newWindow);
                    }}
                  >
                    <StyledTableCell className="theme-color">{customer.flags[customer.flags.length - 1]?.flagged ? <i className="fas fa-flag fa-2x red"></i> : ""}</StyledTableCell>
                    <StyledTableCell className="theme-color">{date.format(new Date(customer.created), "DD/MM/YYYY, HH:mm:ss")}</StyledTableCell>
                    <StyledTableCell className="theme-color">{customer.id}</StyledTableCell>
                    <StyledTableCell className="theme-color">{customer.name}</StyledTableCell>
                    <StyledTableCell className="theme-color">{customer.street}</StyledTableCell>
                    <StyledTableCell className="theme-color">{customer.postcode}</StyledTableCell>
                    <StyledTableCell className="theme-color">{customer.postarea}</StyledTableCell>
                    <StyledTableCell className="theme-color">{getFlag(customer.country)}</StyledTableCell>
                    <StyledTableCell className="theme-color">{customer.email}</StyledTableCell>
                    <StyledTableCell className="theme-color">{customer.phone}</StyledTableCell>
                    <StyledTableCell className="theme-color">{activeSubs}</StyledTableCell>
                    <StyledTableCell className="theme-color">{productText}</StyledTableCell>
                    <StyledTableCell className="theme-color">{singleSales}</StyledTableCell>
                    <StyledTableCell className="theme-color">{customer.arvato?.personid}</StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* Pagination */}

      <TablePagination
        component="div"
        className="theme-color"
        count={totalRows}
        page={currentPage - 1}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default CustomersMUI;
