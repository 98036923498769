import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/products";

export async function getProducts() {
  const { data } = await http.get(apiEndpoint + "/");
  return data;
}

export async function getProductsLatest(country) {
  const { data } = await http.get(apiEndpoint + "/latest/" + country);
  return data;
}

export async function getProductFilters(id) {
  const { data } = await http.post(apiEndpoint + "/filters", { id });
  return data;
}

export async function getProductSeries(country) {
  const { data } = await http.get(apiEndpoint + "/series/" + country);
  return data;
}
