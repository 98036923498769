import React from "react";
import PageTable from "./common/PageTable";
import { Row, Col, Table } from "react-bootstrap";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import { getPhones, getTotalPhonesCount } from "../services/phonesService";
import auth from "../services/authService";
import _ from "lodash";

class Phones extends PageTable {
  state = {
    data: [],
    dataLength: 0,
    totalLength: 0,
    pageSize: 50,
    currentPage: 1,
    searchQuery: "",
    loading: true,
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      const { data } = await getPhones({
        page: this.state.currentPage,
        pagination: this.state.pageSize,
        phrase: this.state.searchQuery,
      });
      const { length } = await getTotalPhonesCount({
        phrase: this.state.searchQuery,
      });
      this.setState({
        data,
        dataLength: length,
        totalLength: length,
        loading: false,
      });
    }
  }

  handlePageChangeQuery = async (page, phrase) => {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      this.setState({ loading: true });
      const { data } = await getPhones({
        page: page,
        pagination: this.state.pageSize,
        phrase: phrase,
      });
      const { length } = await getTotalPhonesCount({
        phrase: phrase,
      });
      this.setState({
        data,
        dataLength: length,
        currentPage: page,
        searchQuery: phrase,
        loading: false,
      });
    }
  };

  render() {
    const { data, dataLength, totalLength, pageSize, currentPage, searchQuery, loading } = this.state;

    const pageName = "Phone";
    const headers = ["Time", "Phone", "Product", "From SMS"];

    return (
      <React.Fragment>
        <PageHeader
          onSearch={this.handleSearchChange}
          onSearchSubmit={this.handlePageChangeQuery}
          pageName={pageName}
          sorted={data}
          count={dataLength}
          totalCount={totalLength}
          searchQuery={searchQuery}
          hasSearch="true"
          loading={loading}
        />
        {!loading && (
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead className="theme-color">
                  <tr>
                    {headers.map((header, index) => {
                      return <th key={index}>{header}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => {
                    return (
                      <tr className="theme-color" key={index}>
                        <td>
                          {Intl.DateTimeFormat("no-NO", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(row.timestamp))}
                        </td>
                        <td>{row.tlf}</td>
                        <td>{row.system}</td>
                        <td>{row.isFromSms && row.isFromSms.toString()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination itemsCount={dataLength} pageSize={pageSize} currentPage={currentPage} phrase={searchQuery} onPageChange={this.handlePageChangeQuery} />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default Phones;
