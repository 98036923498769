import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/phones";

export async function getPhones(item) {
  const { data } = await http.post(apiEndpoint + "/search", item);
  return data;
}

export async function getTotalPhonesCount(item) {
  const { data } = await http.post(apiEndpoint + "/totalcount", item);
  return data;
}
