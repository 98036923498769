import React from "react";
import PageTable from "./common/PageTable";
import { Row, Col, Table } from "react-bootstrap";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import { getBlacklist, countBlacklist, deleteItem, addItem } from "../services/blacklistService";
import _ from "lodash";
import { toast } from "react-toastify";
import authService from "../services/authService";
import date from "date-and-time";

function NewBacklog(props) {
  return (
    <tr>
      <td>
        <select value={props.newBacklog["field"]} onChange={(e) => props.updateField("field", e.target.value)}>
          <option value="personid">Person ID</option>
          <option value="name">Name</option>
          <option value="street">Street</option>
          <option value="phone">Phone</option>
          <option value="affId">Affiliate ID</option>
          <option value="postcode">Postal Code</option>
          <option value="postarea">Area</option>
          <option value="email">Email</option>
          <option value="ip">IP Address</option>
        </select>
      </td>
      <td>
        <input type="checkbox" checked={props.newBacklog["exact"]} onChange={(e) => props.updateField("exact", e.target.checked)}></input>
      </td>
      <td>
        <input type="text" value={props.newBacklog["value"]} onChange={(e) => props.updateField("value", e.target.value)} />
      </td>
      <td>
        <select value={props.newBacklog["country"]} onChange={(e) => props.updateField("country", e.target.value)}>
          <option value="no">Norway</option>
          <option value="se">Sweden</option>
          <option value="dk">Denmark</option>
          <option value="nl">Netherlands</option>
          <option value="de">Germany</option>
        </select>
      </td>
      <td></td>
      <td>
        <input type="button" value="Add" className="btn btn-primary" onClick={props.add} />{" "}
        <input type="button" value="Cancel" className="btn btn-secondary" onClick={props.cancel} />
      </td>
    </tr>
  );
}

class Blacklist extends PageTable {
  state = {
    newBacklog: { field: "name", exact: true, value: "", country: "no" },
    data: [],
    dataLength: 0,
    totalLength: 0,
    pageSize: 10,
    currentPage: 1,
    searchQuery: "",
    loading: true,
    phrase: "",
    newOpen: false,
    addNewLoad: false,
  };

  async componentDidMount() {
    if (authService.hasRole(["admin"])) {
      const { data } = await getBlacklist({ page: this.state.currentPage, pagination: this.state.pageSize, phrase: this.state.phrase });
      const { length } = await countBlacklist();

      this.setState({
        totalLength: length,
        data,
        dataLength: length,
        loading: false,
      });
    }
  }

  handleSearchChange = (searchQuery) => {
    this.setState({
      searchQuery,
    });
  };

  handleNewValueChange = (name, value) => {
    const newBacklog = { ...this.state.newBacklog, [name]: value };

    this.setState({
      newBacklog,
    });
  };

  handleAddNew = async () => {
    this.setState({ addNewLoad: true });

    await addItem(this.state.newBacklog);
    const { data } = await getBlacklist({ page: this.state.currentPage, pagination: this.state.pageSize, phrase: this.state.phrase });
    const { length } = await countBlacklist();
    const newBacklog = { field: "name", exact: true, value: "", country: "no" };

    this.setState({
      addNewLoad: false,
      totalLength: length,
      data,
      dataLength: length,
      newBacklog,
      newOpen: false,
      searchQuery: "",
    });
  };

  handleSearchSubmit = async (page, phrase) => {
    if (authService.hasRole(["admin"])) {
      const { data } = await getBlacklist({ page, pagination: this.state.pageSize, phrase: phrase });
      const { length } = await countBlacklist();
      if (phrase)
        this.setState({
          data,
          dataLength: data.length,
          searchQuery: phrase,
        });
      else
        this.setState({
          data,
          dataLength: length,
          searchQuery: "",
        });
    }
  };

  handlePageChangeQuery = async (page, phrase) => {
    if (authService.hasRole(["admin"])) {
      this.setState({ loading: true });

      const { data } = await getBlacklist({ page, pagination: this.state.pageSize, phrase });

      this.setState({
        phrase,
        currentPage: page,
        data,
        loading: false,
      });
    }
  };

  showNew = () => {
    if (authService.hasRole(["admin"])) {
      this.setState({ newOpen: true });
    }
  };

  hideNew = () => {
    if (authService.hasRole(["admin"])) {
      this.setState({ newOpen: false });
    }
  };

  delete = async (id) => {
    if (authService.hasRole(["admin"])) {
      deleteItem({ _id: id })
        .then(async () => {
          const { data } = await getBlacklist({ page: this.state.currentPage, pagination: this.state.pageSize, phrase: this.state.phrase });
          const { length } = await countBlacklist();

          this.setState({
            totalLength: length,
            data,
            dataLength: data.length,
          });
          toast.success("Deleted blacklist item.");
        })
        .catch((err) => {
          toast.success("Something failed deleting blacklist item.");
        });
    }
  };

  render() {
    const { data, pageSize, currentPage, phrase, loading, dataLength, totalLength, searchQuery, newOpen, newBacklog, addNewLoad } = this.state;

    const pageName = "Blacklist";
    const headers = ["Field", "Exact", "Value", "Country", "Created", ""];

    return (
      <React.Fragment>
        <PageHeader
          hasSearch="true"
          onSearch={this.handleSearchChange}
          onSearchSubmit={this.handleSearchSubmit}
          searchQuery={searchQuery}
          pageName={pageName}
          sorted={data}
          count={dataLength}
          totalCount={totalLength}
          onNew={this.showNew}
          loading={loading}
          selectedFilter={null}
        />
        {!loading && (
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead className="theme-color">
                  <tr>
                    {headers.map((header, index) => {
                      return <th key={index}>{header}</th>;
                    })}
                  </tr>
                </thead>
                <tbody className="theme-color">
                  {newOpen && <NewBacklog newBacklog={newBacklog} updateField={this.handleNewValueChange} cancel={this.hideNew} add={this.handleAddNew} load={addNewLoad} />}
                  {data.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>{row.field}</td>
                        <td>{row.exact.toString()}</td>
                        <td>{row.value}</td>
                        <td>{row.country}</td>
                        <td>{date.format(new Date(row.created), "DD-MM-YYYY HH:MM:ss")}</td>
                        <td>
                          <i className="fas fa-times fa-2x pointer red" onClick={() => this.delete(row._id)}></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination itemsCount={dataLength} pageSize={pageSize} currentPage={currentPage} phrase={phrase} onPageChange={this.handlePageChangeQuery} selectedFilter={null} />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default Blacklist;
