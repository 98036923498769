import React, { Component, useEffect } from "react";
import { useState } from "react";
import { ButtonGroup, Spinner } from "react-bootstrap";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    position: "absolute",
    padding: 0,
  },
};

Modal.setAppElement("#root");

const Timeline = ({ dataFetcher, dataMapper }) => {
  const [open, setOpen] = useState(false);

  const [content, setContent] = useState(null);
  const showModal = (show) => (e) => {
    setOpen(show);
    e.stopPropagation();
  };

  const fetchAndMapData = async () => {
    let data = await dataFetcher();
    try {
      setContent(dataMapper(data));
    } catch (e) {
      console.error("Unable to map", data);
      throw e;
    }
  };

  return (
    <div className="timeline" onClick={showModal(true)}>
      <i className="fas fa-timeline fa-solid"></i>
      <Modal className="theme-color" isOpen={open} onAfterOpen={fetchAndMapData} onRequestClose={showModal(false)} style={customStyles}>
        <div className="timeline-close" onClick={showModal(false)}>
          <i className="fas fa-close fa-solid fa-2x"></i>
        </div>
        <div className="timeline-content">{content}</div>
      </Modal>
    </div>
  );
};

export default Timeline;
