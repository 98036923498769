import React, { Component } from "react";
import Input from "./Input";
import Joi from "joi-browser";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  handleSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleDropdownChange = e => {
    let data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
    if (typeof this.doTypeChange === "function") this.doTypeChange(data);
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  renderButton(label) {
    return (
      <button
        className="btn btn-primary"
        type="submit"
        //disabled={this.validate()}
      >
        {label}
      </button>
    );
  }

  renderInput(name, label, type = "text", disabled) {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        label={label}
        type={type}
        value={data[name]}
        error={errors[name]}
        onChange={this.handleChange}
        disabled={disabled}
      />
    );
  }

  renderDropdown(name, label, values) {
    const { data, errors } = this.state;
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <select
          className={errors[name] ? "form-control is-invalid" : "form-control"}
          value={data[name]}
          onChange={this.handleDropdownChange}
          name={name}
        >
          {values.map((value, index) => {
            return (
              <option value={value} key={index}>
                {value}
              </option>
            );
          })}
        </select>
        <div className="invalid-feedback">{errors[name]}</div>
      </div>
    );
  }

  renderTextarea(name, label) {
    const { data, errors } = this.state;
    return (
      <div>
        <label htmlFor={name}>{label}</label>
        <textarea
          className={errors[name] ? "form-control is-invalid" : "form-control"}
          name={name}
          onChange={this.handleCommentChange}
          rows="10"
          value={data[name]}
        ></textarea>
        <div className="invalid-feedback">{errors[name]}</div>
      </div>
    );
  }
}

export default Form;
