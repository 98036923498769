import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import http from "../../services/httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API;

function SmsBtn({ id, customerId }) {
  const [showModal, setShowModal] = useState(false);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const modal = document.getElementById(`modal-div`);
      if (modal && !modal.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id]);

  const [selectedText, setSelectedText] = useState("");
  const handleClick = (text) => {
    setSelectedText(text);
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const handleSubmit = async (e) => {
    setIsPending(true);
    const message = document.getElementById("modal-sms-textarea").value;

    try {
      const response = await http.post(
        `${apiEndpoint}/customers/sms/${customerId}`,
        {
          message,
        },
        {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );
      if (response.status === 201) {
        toast.success("SMS sent successfully!");
        setShowModal(false);
      } else if (response.status === 400) {
        toast.error("There is no text content, please fill it out.");
      } else {
        toast.error(" There was an error sending the SMS. Please try again later.");
      }
    } catch (error) {
      toast.error(" There was an error sending the SMS. Please try again later.");
    } finally {
      setIsPending(false);
    }
  };

  return (
    <>
      <button
        style={{
          backgroundColor: "green",
          color: "white",
          border: "none",
          padding: "0.5rem",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
        type="button"
        id="modal-sms-btn"
        onClick={() => setShowModal(true)}
      >
        Send SmS
      </button>
      {showModal && (
        <div
          id="modal-div"
          style={{
            backgroundColor: "#DEDEDE",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "1px solid grey",
            padding: " 1rem",
            borderRadius: "5px",
            minWidth: "350px",
            minHeight: "300px",
            maxHeight: "fit-content",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
            zIndex: "100",
          }}
        >
          <textarea
            style={{
              height: "200px",
              borderRadius: "5px",
              padding: "0.2rem",
            }}
            placeholder="send an sms to customer:"
            id="modal-sms-textarea"
          />
          <button
            style={{
              backgroundColor: "green",
              color: "white",
              border: "none",
              padding: "0.5rem",
              borderRadius: "5px",
              marginTop: "1rem",
            }}
            type="button"
            id="modal-sms-btn"
            onClick={handleSubmit}
            disabled={isPending}
          >
            Send sms
          </button>

          <button
            onClick={() => setShowModal(false)}
            style={{
              cursor: "pointer",
              backgroundColor: "#dc3545",
              color: "white",
              border: "none",
              padding: "0.2rem",
              borderRadius: "5px",
              width: "fit-content",
              marginTop: "1rem",
            }}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
}

export default SmsBtn;
