import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Joi from "joi-browser";
import Form from "./common/Form";
import auth from "../services/authService";

class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {}
  };

  schema = {
    username: Joi.string()
      .email()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  doSubmit = async () => {
    const { data, errors } = this.state;
    try {
      await auth.login(data.username, data.password);
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <Container>
        <Row>
          <Col className="mb-3 mt-3">
            <h1>Login</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("username", "Username")}
              {this.renderInput("password", "Password", "password")}
              {this.renderButton("Login")}
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LoginForm;
