import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/customers";

export async function getCustomers(item) {
  const { data } = await http.post(apiEndpoint + "/search", item);
  return data;
}

export async function getTotalCustomersCount(item) {
  const { data } = await http.post(apiEndpoint + "/totalcount", item);
  return data;
}

export async function getCustomerById(customerId) {
  const { data } = await http.get(apiEndpoint + "/" + customerId);
  return data;
}

export async function updateCustomer(item) {
  const { data } = await http.put(apiEndpoint, item);
  return data;
}

export async function createCustomer(item) {
  const { data } = await http.post(apiEndpoint, item);
  return data;
}

export async function cleanupCustomer(customerId) {
  const { data } = await http.get(apiEndpoint + "/cleanup/" + customerId);
  return data;
}

export async function getCustomerDebt(customerId) {
  const { data } = await http.get(apiEndpoint + "/debt/" + customerId);
  return data;
}

export async function postComment(customerId, comment) {
  const { data } = await http.post(apiEndpoint + "/comment/" + customerId, comment);
  return data;
}

export async function changeFlag(item) {
  const { data } = await http.post(apiEndpoint + "/flag", item);
  return data;
}

export async function getSmslog(phone, country) {
  const { data } = await http.get(apiEndpoint + "/smslog/" + phone + "/" + country);
  return data;
}
