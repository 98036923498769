import "../../../node_modules/react-vis/dist/style.css";
import { XYPlot, VerticalBarSeries, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, LabelSeries, Hint } from "react-vis";
import React, { Component } from "react";

const dateFormat = Intl.DateTimeFormat("no-NO", {
  month: "short",
  day: "numeric",
});

class Chart extends Component {
  state = {};

  hint = () => {
    return (
      this.state.hoverValue && [
        <Hint
          align={{ vertical: "topEdge", horizontal: "auto" }}
          value={this.state.hoverValue}
          style={{ background: "#efefef", color: "black", padding: 12, borderRadius: 12, position: "relative" }}
        >
          <div>
            <span>{dateFormat.format(this.state.hoverValue.x)}: </span>
            <span>{this.state.hoverValue.y}</span>
          </div>
        </Hint>,
        <Hint align={{ vertical: "topEdge", horizontal: "right" }} value={this.state.hoverValue} style={{ backgroundColor: "transparent", height: this.props.height - 40 }}>
          <div style={{ borderLeft: "1px dashed #afafaf", height: "100%" }}></div>
        </Hint>,
      ]
    );
  };

  renderType() {
    if (this.props.type === "line") {
      return (
        <XYPlot height={this.props.height} width={this.props.width} xType="time" onMouseLeave={() => this.setState({ hoverValue: undefined })}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis title={this.props.ytitle} />
          <YAxis title={this.props.xtitle} />
          <LineSeries data={this.props.data} onNearestX={(value, { index }) => value !== this.state.hoverValue && this.setState({ hoverValue: value, index })} />
          {this.props.avg && <LineSeries data={this.props.avg} />}
          {this.props.avg && <LabelSeries data={this.props.avgLabel} />}
          {this.hint()}
        </XYPlot>
      );
    } else if (this.props.type === "bar") {
      return (
        <XYPlot height={this.props.height} width={this.props.width} onMouseLeave={() => this.setState({ hoverValue: undefined })}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis title={this.props.ytitle} />
          <YAxis title={this.props.xtitle} />
          <VerticalBarSeries
            data={this.props.data}
            onNearestX={(value, { index }) => value !== this.state.hoverValue && this.setState({ hoverValue: value, index })}
            onSeriesMouseOut={() => this.setState({ hoverValue: null })}
          />
          {this.hint()}
        </XYPlot>
      );
    }
  }
  render() {
    return this.renderType();
  }
}

export default Chart;
