import React from "react";
import PageTable from "./common/PageTable";
import { Row, Col, Table } from "react-bootstrap";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import {
  getShipmentBatches,
  getTotalShipmentBatchesCount,
  createCustomers,
  sendInvoices,
  createCustomersBatch,
  sendInvoicesBatch,
  createFiles,
  captureOrders,
} from "../services/shipmentService";
import _ from "lodash";
import { toast } from "react-toastify";
import auth from "../services/authService";
import date from "date-and-time";

class ShipmentBatches extends PageTable {
  state = {
    data: [],
    dataLength: 0,
    totalLength: 0,
    pageSize: 10,
    currentPage: 1,
    searchQuery: "",
    loading: true,
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && user.role === "admin") {
      const { data } = await getShipmentBatches({
        page: this.state.currentPage,
        pagination: this.state.pageSize,
      });
      const { length } = await getTotalShipmentBatchesCount();
      this.setState({
        data,
        dataLength: length,
        totalLength: length,
        loading: false,
      });
    }
  }

  handlePageChangeQuery = async (page, phrase) => {
    const user = auth.getCurrentUser();
    if (user && user.role === "admin") {
      this.setState({ loading: true });
      const { data } = await getShipmentBatches({
        page: page,
        pagination: this.state.pageSize,
      });
      this.setState({
        data,
        currentPage: page,
        searchQuery: phrase,
        loading: false,
      });
    }
  };

  openRow = (e, batchId) => {
    e.stopPropagation();

    let data = [...this.state.data];
    const index = data.findIndex((x) => x._id === batchId);

    if (index >= 0) {
      data[index].open = !data[index].open;
    }

    this.setState({ data });
  };

  editShipment = (shipment) => {
    this.props.history.push("/shipments/" + shipment.row._id);
  };

  new = () => {
    this.props.history.push("/shipments/new2");
  };

  createFiles = async (e, id) => {
    e.stopPropagation();
    toast.success("Creating files");
    createFiles(id);
  };

  createCustomers = async (e, id) => {
    e.stopPropagation();
    toast.success("Creating customers");
    createCustomers(id);
  };

  createCustomersBatch = async (e, id) => {
    e.stopPropagation();
    toast.success("Creating all customers");
    createCustomersBatch(id);
  };

  sendInvoices = async (e, id) => {
    e.stopPropagation();
    toast.success("Sending invoices");
    sendInvoices(id);
  };

  sendInvoicesBatch = async (e, id) => {
    e.stopPropagation();
    toast.success("Sending all invoices");
    sendInvoicesBatch(id);
  };

  captureOrders = async (e, id) => {
    e.stopPropagation();
    toast.success("Capturing all orders");
    captureOrders(id);
  };

  render() {
    const { data, dataLength, totalLength, pageSize, currentPage, searchQuery, loading } = this.state;

    const pageName = "Shipment";
    const headers = ["Shipment Date", "Country", "Size", "Id", "Klarna Capture"];

    return (
      <React.Fragment>
        <PageHeader onSearch={this.handleSearch} pageName={pageName} sorted={data} count={dataLength} totalCount={totalLength} onNew={this.new} loading={loading} />
        {!loading && (
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead className="theme-color">
                  <tr>
                    {headers.map((header, index) => {
                      return <th key={index}>{header}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => {
                    const datePattern = "DD.MM.YYYY";
                    const shipment_date = new Date(row.shipment_date);
                    const showKlarnaCaptured = shipment_date.getTime() < new Date().getTime();

                    return (
                      <React.Fragment key={index}>
                        <tr className="pointer theme-color" onClick={(e) => this.openRow(e, row._id)}>
                          <td>{row.shipment_date && date.format(shipment_date, datePattern)}</td>
                          <td>{row.country}</td>
                          <td>{row.size}</td>
                          <td>{row._id}</td>
                          <td>
                            {row.klarnaCaptured !== undefined && row.klarnaCaptured === false && showKlarnaCaptured && (
                              <button className="btn btn-success" onClick={(e) => this.captureOrders(e, row._id)}>
                                Capture klarna
                              </button>
                            )}
                          </td>
                        </tr>
                        {row.open && (
                          <tr>
                            <td colSpan="4">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Product</th>
                                    <th>Size</th>
                                    <th>Code / Type</th>
                                    <th>Operator</th>
                                    <th>Vilkar</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {row.shipments.map((shipment, shipI) => {
                                    return (
                                      <tr key={shipI}>
                                        <td>{shipment.product}</td>
                                        <td>{shipment.size}</td>
                                        <td>{shipment.shippingCode ?? shipment.type}</td>
                                        <td>{shipment.fulfillmentOperator}</td>
                                        <td>{shipment.vilkar}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination itemsCount={dataLength} pageSize={pageSize} currentPage={currentPage} phrase={searchQuery} onPageChange={this.handlePageChangeQuery} />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default ShipmentBatches;
