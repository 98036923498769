import React from "react";
import Form from "./common/Form";
import { Container, Row, Col, Table } from "react-bootstrap";
import Joi from "joi-browser";
import { getShipmentsCount2, createShipment2 } from "../services/shipmentService";
import { createShipmentJob } from "../services/jobsService";
import { getProducts } from "../services/productService";
import auth from "../services/authService";
import { toast } from "react-toastify";
import date from "date-and-time";

class EditShipment extends Form {
  state = {
    data: {
      date: date.format(new Date(), "YYYY-MM-DD"),
      shipments: [
        { country: "no", countryName: "Norway", selected: false, products: [] },
        { country: "se", countryName: "Sweden", selected: false, products: [] },
        { country: "fi", countryName: "Finland", selected: false, products: [] },
        { country: "dk", countryName: "Denmark", selected: false, products: [] },
        { country: "nl", countryName: "Netherlands", selected: false, products: [] },
        { country: "de", countryName: "Germany", selected: false, products: [] },
      ],
    },
    submitted: false,
    counting: false,
    errors: {},
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && user.role === "admin") {
      let { data } = this.state;

      let result = await getProducts();

      for (let i = 0; i < data.shipments.length; i++) {
        let shipment = data.shipments[i];
        for (let u = 0; u < result.length; u++) {
          const product = result[u];
          shipment.products.push({
            name: product.name,
            selected: true,
            types: [
              {
                name: "initial",
                code: "",
                selected: true,
                count: 0,
                users: [],
              },
              {
                name: "regular",
                selected: true,
                seqs: [
                  { number: 1, code: "", selected: true, count: 0, users: [] },
                  { number: 2, code: "", selected: true, count: 0, users: [] },
                  { number: 3, code: "", selected: true, count: 0, users: [] },
                  { number: 4, code: "", selected: true, count: 0, users: [] },
                  { number: 5, code: "", selected: true, count: 0, users: [] },
                  { number: 6, code: "", selected: true, count: 0, users: [] },
                  { number: 7, code: "", selected: true, count: 0, users: [] },
                  { number: 8, code: "", selected: true, count: 0, users: [] },
                  { number: 9, code: "", selected: true, count: 0, users: [] },
                  { number: 10, code: "", selected: true, count: 0, users: [] },
                  { number: 11, code: "", selected: true, count: 0, users: [] },
                  { number: 12, code: "", selected: true, count: 0, users: [] },
                  { number: 13, code: "", selected: true, count: 0, users: [] },
                  { number: 14, code: "", selected: true, count: 0, users: [] },
                  { number: 15, code: "", selected: true, count: 0, users: [] },
                  { number: 16, code: "", selected: true, count: 0, users: [] },
                  { number: 17, code: "", selected: true, count: 0, users: [] },
                  { number: 18, code: "", selected: true, count: 0, users: [] },
                  { number: 19, code: "", selected: true, count: 0, users: [] },
                  { number: 20, code: "", selected: true, count: 0, users: [] },
                ],
              },
              {
                name: "holdback",
                code: "",
                selected: true,
                count: 0,
                users: [],
              },
              {
                name: "custom",
                code: "",
                selected: true,
                count: 0,
                users: [],
              },
              {
                name: "return",
                code: "",
                selected: true,
                count: 0,
                users: [],
              },
            ],
          });
        }
      }

      this.setState({ data });
    }
  }

  getShipmentCount = async () => {
    let { data, counting } = { ...this.state };

    const object = {
      date: data.date,
      shipments: data.shipments,
    };

    this.setState({ counting: true });

    data.shipments = await getShipmentsCount2(object);

    this.setState({ data, counting: false });
  };

  doSubmit = async (e) => {
    e.preventDefault();
    let { data, errors, submitted } = this.state;
    toast.success("Shipment order initiated.");
    //submitted = true;
    //this.setState({ submitted });
    try {
      await createShipment2(data);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        errors.name = ex.response.data;
        this.setState({ errors });
      }
      if (ex.response && ex.response.status === 404) {
        toast.error("No orders found");
      }
    }
  };

  handleShipmentDateChange = (e) => {
    let { data } = this.state;
    const { value } = e.currentTarget;
    data.date = value;
    this.setState({ data });
  };

  handleCountrySelect = (e) => {
    let { data } = this.state;
    const options = e.currentTarget.options;
    let optionsArray = [];

    for (let i = 0; i < options.length; i++) {
      optionsArray.push({
        country: options[i].value,
        selected: options[i].selected,
      });
    }

    for (let i = 0; i < data.shipments.length; i++) {
      let shipment = data.shipments[i];

      const index = optionsArray.findIndex((x) => x.country === shipment.country && x.selected === true);
      if (index >= 0) {
        shipment.selected = true;
      } else {
        shipment.selected = false;
      }
    }

    this.setState({ data });
  };

  handleProductSelect = (e) => {
    let { data } = this.state;
    const selectName = e.currentTarget.name;
    const options = e.currentTarget.options;
    let optionsArray = [];

    for (let i = 0; i < options.length; i++) {
      optionsArray.push({
        product: options[i].value,
        selected: options[i].selected,
      });
    }

    const index = data.shipments.findIndex((x) => x.country === selectName);

    if (index >= 0) {
      for (let i = 0; i < data.shipments[index].products.length; i++) {
        let product = data.shipments[index].products[i];
        const optionsIndex = optionsArray.findIndex((x) => x.product === product.name && x.selected === true);
        if (optionsIndex >= 0) {
          product.selected = true;
        } else {
          product.selected = false;
        }
      }
      this.setState({ data });
    }
  };

  handleTypeChange = (e, country, product) => {
    let { data } = this.state;
    const type = e.currentTarget.name;

    const shipmentIndex = data.shipments.findIndex((x) => x.country === country);
    if (shipmentIndex >= 0) {
      const productIndex = data.shipments[shipmentIndex].products.findIndex((x) => x.name === product);
      if (productIndex >= 0) {
        const typeIndex = data.shipments[shipmentIndex].products[productIndex].types.findIndex((x) => x.name === type);
        if (typeIndex >= 0) {
          data.shipments[shipmentIndex].products[productIndex].types[typeIndex].selected = !data.shipments[shipmentIndex].products[productIndex].types[typeIndex].selected;
          this.setState({ data });
        }
      }
    }
  };

  handleSeqChange = (e, country, product) => {
    let { data } = this.state;
    const type = "regular";
    const options = e.currentTarget.options;

    const shipmentIndex = data.shipments.findIndex((x) => x.country === country);
    if (shipmentIndex >= 0) {
      const productIndex = data.shipments[shipmentIndex].products.findIndex((x) => x.name === product);
      if (productIndex >= 0) {
        const typeIndex = data.shipments[shipmentIndex].products[productIndex].types.findIndex((x) => x.name === type);
        if (typeIndex >= 0) {
          for (let i = 0; i < options.length; i++) {
            const option = options[i];
            if (option.selected) {
              data.shipments[shipmentIndex].products[productIndex].types[typeIndex].seqs[i].selected = true;
            } else {
              data.shipments[shipmentIndex].products[productIndex].types[typeIndex].seqs[i].selected = false;
            }
          }

          this.setState({ data });
        }
      }
    }
  };

  handleCodeChange = (e, country, product, seq) => {
    let { data } = this.state;
    let type = e.currentTarget.name;
    if (type.toString().startsWith("regular")) type = "regular";
    const value = e.currentTarget.value;

    const shipmentIndex = data.shipments.findIndex((x) => x.country === country);
    if (shipmentIndex >= 0) {
      const productIndex = data.shipments[shipmentIndex].products.findIndex((x) => x.name === product);
      if (productIndex >= 0) {
        const typeIndex = data.shipments[shipmentIndex].products[productIndex].types.findIndex((x) => x.name === type);
        if (typeIndex >= 0) {
          if (seq !== null) {
            const seqIndex = data.shipments[shipmentIndex].products[productIndex].types[typeIndex].seqs.findIndex((x) => x.number === seq);
            if (seqIndex >= 0) {
              data.shipments[shipmentIndex].products[productIndex].types[typeIndex].seqs[seqIndex].code = value;
            }
          } else {
            data.shipments[shipmentIndex].products[productIndex].types[typeIndex].code = value;
          }
          this.setState({ data });
        }
      }
    }
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.push("/shipments");
  };

  render() {
    return (
      <Container>
        <Row>
          <Col className="mb-3 mt-3">{this.props.match.params.shipmentId !== "new" ? <h1>Editing shipment</h1> : <h1>Creating new shipment</h1>}</Col>
        </Row>
        <Row>
          <Col>
            <form onSubmit={this.doSubmit}>
              <div className="mb-3">
                {!this.state.submitted && this.renderButton("Issue shipment")}

                <button className="btn btn-secondary ml-3" onClick={this.getShipmentCount} type="button" disabled={this.state.counting}>
                  Count shipments
                </button>

                <button className="btn btn-secondary ml-3" onClick={this.handleBack}>
                  Back
                </button>
              </div>
              <div className="mb-3">
                <label htmlFor="date">Shipment Date</label>
                <br />
                <input className="date" type="date" name="date" value={this.state.data.date} onChange={this.handleShipmentDateChange} min="" max=""></input>
              </div>
              <div className="mb-3">
                <label htmlFor="countries">Countries</label>
                <br />
                <select name="countries" size={this.state.data.shipments.length} onChange={this.handleCountrySelect} multiple>
                  {this.state.data.shipments.map((shipment, index) => {
                    return (
                      <option key={index} value={shipment.country} selected={shipment.selected}>
                        {shipment.countryName}
                      </option>
                    );
                  })}
                </select>
              </div>
              {this.state.data.shipments.map((shipment, index) => {
                if (shipment.selected)
                  return (
                    <div key={index} className="mb-3">
                      <label htmlFor={shipment.country}>{shipment.countryName} products</label>
                      <br />
                      <select name={shipment.country} size={shipment.products.length} onChange={this.handleProductSelect} multiple>
                        {shipment.products.map((product, index) => {
                          return (
                            <option key={index} value={product.name} selected={product.selected}>
                              {product.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
              })}

              {this.state.data.shipments.map((shipment, index) => {
                if (shipment.selected) {
                  return (
                    <Row key={index}>
                      <Col>
                        {shipment.countryName}
                        <Table striped bordered>
                          <thead>
                            <tr>
                              <th>Products</th>
                              <th>Setup</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipment.products.map((product, productI) => {
                              if (product.selected) {
                                return (
                                  <tr key={productI}>
                                    <td>{product.name}</td>
                                    <td>
                                      <Table striped bordered>
                                        <thead>
                                          <tr>
                                            <th>Types</th>
                                            <th>Seqs</th>
                                            <th>Codes</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {product.types &&
                                            product.types.map((type, typeI) => {
                                              return (
                                                <tr key={typeI}>
                                                  <td>
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        checked={type.selected}
                                                        name={type.name}
                                                        onChange={(e) => this.handleTypeChange(e, shipment.country, product.name)}
                                                      />{" "}
                                                      <label htmlFor={type.name}>{type.name}</label>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {type.name === "regular" && type.selected && (
                                                      <select name="seqs" size="20" onChange={(e) => this.handleSeqChange(e, shipment.country, product.name)} multiple>
                                                        {type.seqs.map((seq, seqI) => {
                                                          return (
                                                            <option key={seqI} value={seq.number} selected={seq.selected}>
                                                              {seq.number}
                                                            </option>
                                                          );
                                                        })}
                                                      </select>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {type.selected && type.name !== "regular" && (
                                                      <div>
                                                        <input
                                                          type="text"
                                                          name={type.name}
                                                          value={type.code}
                                                          onChange={(e) => this.handleCodeChange(e, shipment.country, product.name, null)}
                                                        />{" "}
                                                        {type.count !== 0 && type.count}
                                                      </div>
                                                    )}
                                                    {type.selected &&
                                                      type.name === "regular" &&
                                                      type.seqs.length > 0 &&
                                                      type.seqs.map((seq, seqI) => {
                                                        const nameString = "" + type.name + seq.number;
                                                        if (seq.selected)
                                                          return (
                                                            <div key={seqI}>
                                                              <label htmlFor={seq.number}>{seq.number}</label>{" "}
                                                              <input
                                                                type="text"
                                                                name={nameString}
                                                                value={seq.code}
                                                                onChange={(e) => this.handleCodeChange(e, shipment.country, product.name, seq.number)}
                                                              />{" "}
                                                              {seq.count !== 0 && seq.count}
                                                            </div>
                                                          );
                                                      })}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      </Table>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  );
                }
              })}
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditShipment;
