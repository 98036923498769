import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class PageTable extends Component {
  handlePageChange = (page) => {
    let currentPage = { ...this.state.currentPage };
    currentPage = page;
    this.setState({ currentPage });
  };

  /*handleFilterClick = (filter) => {
    let selectedFilter = { ...this.state.selectedFilter };
    selectedFilter = filter;
    this.setState({ selectedFilter, currentPage: 1, searchQuery: "" });
  };*/

  handleSort = (path) => {
    const sortColumn = { ...this.state.sortColumn };

    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.setState({ sortColumn });
  };

  handleSearchChange = (query) => {
    let searchQuery = { ...this.state.searchQuery };
    searchQuery = query;
    this.setState({ searchQuery });
  };

  applyFilters(
    filtered,
    searchQuery,
    searchProperties,
    filterName,
    selectedFilter
  ) {
    let filteredTmp = [];
    if (searchQuery) {
      for (let i = 0; i < searchProperties.length; i++) {
        filtered.map((data) => {
          let result = data[searchProperties[i]];
          if (!isNaN(result)) result = result.toString();
          if (
            result &&
            result.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            if (filteredTmp.indexOf(data) === -1) filteredTmp.push(data);
          }
          return data;
        });
      }
      filtered = filteredTmp;
    } else if (selectedFilter && selectedFilter !== "All") {
      filtered = filtered.filter((customer) => {
        if (customer.subs) {
          if (
            customer.subs.findIndex(
              (sub) => sub.product.name === selectedFilter
            ) > -1
          )
            return true;
        }
        return false;
      });
    }
    return filtered;
  }

  renderSortIcon = (column) => {
    const { sortColumn } = this.state;

    if (column !== sortColumn.path) return <i className="fa fa-sort" />;
    if (sortColumn.order === "asc") return <i className="fa fa-sort-up" />;

    return <i className="fa fa-sort-down" />;
  };

  renderNoData(pageName) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>{pageName}s</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Loading data....</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.new && (
              <button className="btn btn-primary" onClick={this.new}>
                New
              </button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default PageTable;
