import { lightTheme, darkTheme } from "./Theme";

export function toggleTheme() {
  const rootElement = document.querySelector("body");
  const currentTheme = rootElement.getAttribute("data-theme");
  const newTheme = currentTheme === "light" ? "dark" : "light";
  rootElement.setAttribute("data-theme", newTheme);
  localStorage.setItem("theme", newTheme);

  // Update CSS variables
  const theme = newTheme === "light" ? lightTheme : darkTheme;
  for (const key in theme) {
    rootElement.style.setProperty(`--${key}`, theme[key]);
  }
}

export function initializeTheme() {
  const rootElement = document.querySelector("body");
  const savedTheme = localStorage.getItem("theme") || "light"; // Default to light
  rootElement.setAttribute("data-theme", savedTheme);

  // Update CSS variables
  const theme = savedTheme === "light" ? lightTheme : darkTheme;
  for (const key in theme) {
    rootElement.style.setProperty(`--${key}`, theme[key]);
  }
}
