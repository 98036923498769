import React from "react";

export const getFlag = (countryCode) => {
  switch (countryCode) {
    case "se":
      return (
        <span role="img" aria-label="sweden">
          🇸🇪
        </span>
      );
    case "no":
      return (
        <span role="img" aria-label="norway">
          🇳🇴
        </span>
      );
    case "fi":
      return (
        <span role="img" aria-label="finland">
          🇫🇮
        </span>
      );
    case "dk":
      return (
        <span role="img" aria-label="denmark">
          🇩🇰
        </span>
      );
    case "nl":
      return (
        <span role="img" aria-label="netherland">
          🇳🇱
        </span>
      );
    case "de":
      return (
        <span role="img" aria-label="germany">
          🇩🇪
        </span>
      );
    case "at":
      return (
        <span role="img" aria-label="austria">
          🇦🇹
        </span>
      );
    case "be":
      return (
        <span role="img" aria-label="belgium">
          🇧🇪
        </span>
      );
    case "ch":
      return (
        <span role="img" aria-label="switzerland">
          🇨🇭
        </span>
      );
    case "pl":
      return (
        <span role="img" aria-label="poland">
          🇵🇱
        </span>
      );
    case "cz":
      return (
        <span role="img" aria-label="czechia">
          🇨🇿
        </span>
      );
    case "ie":
      return (
        <span role="img" aria-label="ireland">
          🇮🇪
        </span>
      );
    case "gb":
      return (
        <span role="img" aria-label="united kingdom">
          🇬🇧
        </span>
      );
    case "pt":
      return (
        <span role="img" aria-label="portugal">
          🇵🇹
        </span>
      );
    case "fr":
      return (
        <span role="img" aria-label="france">
          🇫🇷
        </span>
      );
    default:
      return (
        <span role="img" aria-label="norway">
          🇳🇴
        </span>
      );
  }
};
