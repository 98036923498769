import React from "react";
import PageTable from "./common/PageTable";
import { Row, Col, Table } from "react-bootstrap";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import Dialog from "./common/Dialog";
import { paginate } from "../utils/paginate";
import {
  getInvoiceById,
  getCreditById,
  deleteOrder,
  creditOrder,
  creditArvatoOrder,
  refundKlarnaOrder,
  cancelKlarnaOrder,
  refundAfterpayOrder,
  refundStripeOrder,
} from "../services/orderService";
import { getCustomerDebt } from "../services/customerService";
import _ from "lodash";
import { toast } from "react-toastify";
import auth from "../services/authService";

import Timeline from "./common/Timeline";
import { DL, DT, DD } from "./DefinitionList";

const stopPropagation = (e) => {
  e.stopPropagation();
};

const MapObject = (obj) => {
  let keys = Object.keys(obj);

  keys.forEach((key) => {
    const value = obj[key];
    if (key === "payload") {
      obj = { ...obj, ...value };

      Object.keys(value).forEach((newKey) => keys.push(newKey));
    }
  });

  keys.forEach((key) => {
    if (key === "date") {
      obj[key + "Mapped"] = Intl.DateTimeFormat("no-NO", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(obj[key]));
    }
  });

  return (
    <>
      <DL>
        {keys
          .filter((key) => key !== "payload")
          .map((key) => {
            const value = obj[key + "Mapped"] ?? obj[key];
            return (
              <React.Fragment key={key}>
                <DT cols={6}>{_.startCase(key)}</DT>
                <DD cols={6}>{typeof value === "object" ? MapObject(value) : String(value)}</DD>
              </React.Fragment>
            );
          })}
      </DL>
    </>
  );
};

const PlaceReservationTimeline = ({ placeReservation }) => {
  let dataFetcher = () => [].concat(placeReservation);
  let dataMapper = (data) => {
    return data.map((event, index) => <div key={index}>{MapObject(event)}</div>);
  };

  return <Timeline dataFetcher={dataFetcher} dataMapper={dataMapper} />;
};

class Orders extends PageTable {
  state = {
    dialog: {},
    data: [],
    filters: [],
    selectedFilter: "",
    filterName: "price",
    pageSize: 10,
    currentPage: 1,
    sortColumn: {},
    searchProperties: ["price", "ip"],
    searchQuery: "",
    showDelete: true,
    sub: {},
  };

  downloadInvoiceFile = async (id) => {
    const invoice = await getInvoiceById(id);
    const fileURL = URL.createObjectURL(invoice);
    window.open(fileURL);
  };

  downloadCreditFile = async (id) => {
    const invoice = await getCreditById(id);
    const fileURL = URL.createObjectURL(invoice);
    window.open(fileURL);
  };

  componentDidUpdate(prevProps, prevState) {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      if (this.props.orders && this.state.data !== this.props.orders) {
        const data = this.props.orders;
        this.setState({ data, sub: this.props.sub });
      }
    }
  }

  componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      if (this.props.orders && this.state.data !== this.props.orders) {
        const data = this.props.orders;
        this.setState({ data, sub: this.props.sub });
      }
    }
  }

  editOrder = (order) => {
    if (!order.row.shipped_date)
      this.props.history.push("/customers/" + this.props.match.params.customerid + "/subs/" + this.props.match.params.subid + "/orders/" + order.row._id);
  };

  new = () => {
    this.props.history.push("/customers/" + this.props.match.params.customerid + "/subs/" + this.props.match.params.subid + "/orders/new");
  };

  confirmDelete = (e, id) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.id = id;
    dialog.title = "Delete?";
    dialog.text = "Sure you want to delete this order? (this action cannot be reverted)";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = this.delete;
    this.setState({ dialog });
  };

  confirmCredit = (e, row) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Send credit to Visma?";
    dialog.text = "Sure you want to send a credit to Visma for this order? (this action cannot be reverted)";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.creditOrder(row);
    this.setState({ dialog });
  };

  confirmCreditArvato = (e, row) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Send credit to Arvato?";
    dialog.text = "Sure you want to send a credit to Arvato for this order? (this action cannot be reverted)";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.creditOrderArvato(row);
    this.setState({ dialog });
  };

  confirmRefund = (e, row) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Refund order in klarna?";
    dialog.text = "Are you sure you want to refund this order in klarna for this customer? (this action cannot be reverted)";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.refundOrderKlarna(row);
    this.setState({ dialog });
  };

  confirmAfterpayRefund = (e, row) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Refund invoice?";
    dialog.text = "Are you sure you want to refund the afterpay invoice for this customer? (this action cannot be reverted)";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.refundOrderAfterpay(row);
    this.setState({ dialog });
  };

  confirmStripeRefund = (e, row) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Refund invoice?";
    dialog.text = "Are you sure you want to refund the stripe invoice for this customer? (this action cannot be reverted)";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.refundOrderStripe(row);
    this.setState({ dialog });
  };

  refundOrderKlarna = async (row) => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Refunding klarna order...");

    try {
      const refund = await refundKlarnaOrder(row);
      if (refund) {
        let data = this.state.data;
        const index = data.findIndex((x) => x._id === refund._id);
        if (index >= 0) {
          data[index].klarna.refunded = true;
          this.setState({ data });
          toast.success("Successfully refunded order");
        } else {
          toast.error("Couldnt update state");
        }
      } else {
        toast.error("Something failed refunding order with klarna");
      }
    } catch (err) {
      toast.error("Something failed with klarna: " + err);
      console.log(err);
    }
  };

  refundOrderAfterpay = async (row) => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Refunding afterpay invoice...");

    try {
      const refund = await refundAfterpayOrder(row);
      if (refund) {
        let data = this.state.data;
        const index = data.findIndex((x) => x._id === refund._id);
        if (index >= 0) {
          data[index].afterpay.refunded = true;
          this.setState({ data });
          toast.success("Successfully refunded invoice");
        } else {
          toast.error("Couldnt update state");
        }
      } else {
        toast.error("Something failed refunding invoice with afterpay");
      }
    } catch (err) {
      toast.error("Something failed with afterpay: " + err);
      console.log(err);
    }
  };

  refundOrderStripe = async (row) => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Refunding stripe invoice...");

    try {
      const refund = await refundStripeOrder(row);
      if (refund) {
        let data = this.state.data;
        const index = data.findIndex((x) => x._id === refund._id);
        if (index >= 0) {
          data[index].refunded = true;
          this.setState({ data });
          toast.success("Successfully refunded invoice");
        } else {
          toast.error("Couldnt update state");
        }
      } else {
        toast.error("Something failed refunding invoice with afterpay");
      }
    } catch (err) {
      toast.error("Something failed with afterpay: " + err);
      console.log(err);
    }
  };

  confirmCancel = (e, row) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Cancel order in klarna?";
    dialog.text = "Are you sure you want to cancel this order? (this action cannot be reverted)";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.cancelOrderKlarna(row);
    this.setState({ dialog });
  };

  cancelOrderKlarna = async (row) => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Cancelling klarna order...");

    try {
      const refund = await cancelKlarnaOrder(row);
      if (refund) {
        let data = this.state.data;
        const index = data.findIndex((x) => x._id === refund._id);
        if (index >= 0) {
          data[index].klarna.orderId = undefined;
          data[index].klarna.refunded = true;
          this.setState({ data });
          toast.success("Successfully cancelled order");
        } else {
          toast.error("Couldnt update state");
        }
      } else {
        toast.error("Something failed cancelling order with klarna");
      }
    } catch (err) {
      toast.error("Something failed with klarna: " + err);
      console.log(err);
    }
  };

  creditOrderArvato = async (row) => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Sending order credit to Arvato...");
    delete row.invoice.file;

    try {
      const credit = await creditArvatoOrder(row);
      if (credit) {
        let data = this.state.data;
        const index = data.findIndex((x) => x._id === credit._id);
        if (index >= 0) {
          data[index].credit = credit.credit;
          this.setState({ data });
          toast.success("Successfully sent to Arvato");
        } else {
          toast.error("Couldnt update state");
        }
      } else {
        toast.error("Something failed sending credit to Arvato");
      }
    } catch (err) {
      toast.error("Something failed with Arvato");
    }
  };

  closeModal = () => {
    this.setState({ dialog: { open: false } });
  };

  delete = async (e, id) => {
    e.stopPropagation();
    let { dialog, data } = this.state;
    dialog.open = false;
    await deleteOrder(dialog.id);
    const index = data.findIndex((order) => order._id === dialog.id);
    data.splice(index);
    this.setState({ data });
  };

  creditOrder = async (row) => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Sending order credit to Visma...");
    delete row.invoice.file;
    row.invoice.productName = this.props.sub.product.name;
    try {
      const credit = await creditOrder(row, this.props.match.params.subid);
      if (credit) {
        let data = this.state.data;
        const index = data.findIndex((x) => x._id === credit._id);
        if (index >= 0) {
          data[index].credit = credit.credit;
          this.setState({ data });
          toast.success("Successfully sent to Visma");
        } else {
          toast.error("Couldnt update state");
        }
      } else {
        toast.error("Something failed sending credit to Visma");
      }
    } catch (err) {
      toast.error("Something failed with Visma");
    }
  };

  getDebt = async (row) => {
    let { data } = { ...this.state };
    const result = await getCustomerDebt(row.invoice.customerNo);
    if (result) {
      const index = data.findIndex((data) => data.invoice.id === result.invoiceNo);
      data[index].debt = result.amount;
      this.setState({ data });
    } else {
      toast.error("No data found");
    }
  };

  render() {
    const { data, pageSize, currentPage, searchQuery, searchProperties, selectedFilter, filterName, sortColumn, sub } = this.state;
    const { length: count } = data;
    const pageName = this.props.singleOrders ? "Single Order" : "Order";
    const headers = this.props.singleOrders
      ? [
          "Created",
          "Shipped",
          "Product",
          "Type",
          "Price",
          "Discount",
          "Shipping Price",
          "Shipping Partner",
          "Tracking",
          "Source",
          "Factoring",
          "Ip",
          "Credit Check",
          "InvoiceNo",
          "",
          "",
        ]
      : [
          "Created",
          "Shipped",
          "Deadline",
          "Next Delivery",
          "Type",
          "Sequence",
          "Price",
          "Discount",
          "Shipping Price",
          "Shipping Code",
          "Shipping Partner",
          "Tracking",
          "Ip",
          "Credit Check",
          "InvoiceNo",
          "",
          "",
        ];

    if (count === 0) return this.renderNoData(pageName);

    let filtered = data;

    filtered = this.applyFilters(filtered, searchQuery, searchProperties, filterName, selectedFilter);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginated = paginate(sorted, currentPage, pageSize);

    return (
      <React.Fragment>
        <Dialog
          open={this.state.dialog.open}
          doNo={this.closeModal}
          doYes={this.state.dialog.yesAction}
          title={this.state.dialog.title}
          text={this.state.dialog.text}
          yes={this.state.dialog.yes}
          no={this.state.dialog.no}
        />
        <PageHeader
          onSearch={this.handleSearch}
          pageName={pageName}
          sorted={sorted}
          count={count}
          searchQuery={searchQuery}
          onNew={this.props.singleOrders ? undefined : this.new}
        />
        <Row>
          <Col>
            <Table striped bordered hover className="theme-color">
              <thead>
                <tr>
                  {headers.map((header, index) => {
                    return <th key={index}>{header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {paginated.map((row, index) => {
                  let deadline = null;
                  if (row.shipped_date && this.props.sub) {
                    deadline = new Date(row.shipped_date);
                    if (row.type === "initial") deadline.setDate(deadline.getDate() + this.props.sub.product.deadline + 1);
                    else deadline.setDate(deadline.getDate() + this.props.sub.product.regularDeadline + 1);
                  }

                  const factoring = this.state.sub?.factoring ?? row.factoring;
                  return (
                    <tr key={index} onClick={() => this.editOrder({ row })} className={row.shipped_date ? "" : "pointer"}>
                      <td>
                        {row.created &&
                          Intl.DateTimeFormat("no-NO", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(row.created))}
                      </td>
                      <td>
                        {row.shipped_date &&
                          Intl.DateTimeFormat("no-NO", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(row.shipped_date))}
                      </td>
                      {!this.props.singleOrders && (
                        <td>
                          {deadline &&
                            Intl.DateTimeFormat("no-NO", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                            }).format(new Date(deadline))}
                        </td>
                      )}
                      {!this.props.singleOrders && (
                        <td>
                          {row.nextDelivery &&
                            Intl.DateTimeFormat("no-NO", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                            }).format(new Date(row.nextDelivery))}
                        </td>
                      )}
                      {this.props.singleOrders && <td>{row.product.series}</td>}

                      <td>{row.type}</td>
                      {!this.props.singleOrders && <td>{row.seq}</td>}
                      <td>{row.price}</td>
                      <td>{row.discount}</td>
                      <td>{row.shippingPrice}</td>
                      {!this.props.singleOrders && <td>{row.shippingCode}</td>}
                      <td>{row.shipped_date ? (row.sentToByrd ? "Byrd" : "Wepack") : ""}</td>
                      <td>
                        {row.tracking && (
                          <span className="no-break">
                            Status:&nbsp;{row.tracking.status} <br />
                            <a href={row.tracking.url} onClick={stopPropagation} target="_blank" rel="noopener noreferrer">
                              Link
                            </a>
                          </span>
                        )}
                      </td>
                      {this.props.singleOrders && <td>{row.source?.type}</td>}
                      {this.props.singleOrders && <td>{row.factoring}</td>}
                      <td>{row.ip}</td>
                      <td>{row.placeReservation && <PlaceReservationTimeline placeReservation={row.placeReservation} />}</td>
                      <td>{row.invoice?.id}</td>
                      <td>
                        {row.invoice && (
                          <button className="btn btn-success" onClick={(e) => this.downloadInvoiceFile(row.invoice._id)}>
                            Invoice
                          </button>
                        )}
                      </td>
                      <td>
                        {(row.klarna?.refunded || row.afterpay?.refunded || row.refunded) && <span class="badge bg-secondary">Refunded</span>}
                        {row.invoice ? (
                          row.credit ? (
                            <button
                              className="btn btn-success"
                              onClick={(e) => {
                                this.downloadCreditFile(row.credit);
                              }}
                            >
                              Download Credit
                            </button>
                          ) : (
                            row.arvato &&
                            !row.klarna?.orderId && (
                              <button className="btn btn-danger" onClick={(e) => this.confirmCreditArvato(e, row)}>
                                Credit
                              </button>
                            )
                          )
                        ) : (
                          this.state.showDelete &&
                          !row.klarna?.orderId &&
                          !row.factoring === "stripe" &&
                          auth.hasRole("admin") && (
                            <button className="btn btn-danger" onClick={(e) => this.confirmDelete(e, row._id)}>
                              Delete
                            </button>
                          )
                        )}
                        {row.klarna && !row.refunded && !row.klarna.refunded && row.klarna.captured && (
                          <button className="btn btn-danger" onClick={(e) => this.confirmRefund(e, row)}>
                            Refund
                          </button>
                        )}
                        {row.klarna && !row.refunded && !row.klarna.refunded && !row.klarna.captured && (
                          <button className="btn btn-danger" onClick={(e) => this.confirmCancel(e, row)}>
                            Cancel
                          </button>
                        )}
                        {factoring === "afterpay" && row.shipped_date && !row.refunded && !row.afterpay?.refunded && (
                          <button className="btn btn-danger" onClick={(e) => this.confirmAfterpayRefund(e, row)}>
                            Refund
                          </button>
                        )}
                        {factoring === "stripe" && row.shipped_date && !row.refunded && (
                          <button className="btn btn-danger" onClick={(e) => this.confirmStripeRefund(e, row)}>
                            Refund
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination itemsCount={sorted.length} pageSize={pageSize} currentPage={currentPage} onPageChange={this.handlePageChange} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Orders;
