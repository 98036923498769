import React from "react";
import { Row, Col, Spinner, Button, ButtonGroup } from "react-bootstrap";
import Dialog from "./common/Dialog";
import _ from "lodash";
import { toast } from "react-toastify";
import auth from "../services/authService";
import { getSubscription, cancelSubscription, terminateSubscription, reactivateSubscription, getSubscriptionTimeline } from "../services/arvatoRecurringService";
import { DD, DL, DT } from "./DefinitionList";
import Timeline from "./common/Timeline";

const MapObject = (obj) => {
  let keys = Object.keys(obj);

  keys.forEach((key) => {
    const value = obj[key];
    if (key === "payload") {
      obj = { ...obj, ...value };

      Object.keys(value).forEach((newKey) => keys.push(newKey));
    }
  });

  keys.forEach((key) => {
    if (key === "initiatedAt") {
      obj[key] = Intl.DateTimeFormat("no-NO", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(new Date(obj[key]));
    } else if (key === "recordedAt" || key === "creditedAt") {
      obj[key] = Intl.DateTimeFormat("no-NO", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(obj[key]));
    }
  });

  return (
    <>
      <DL>
        {keys
          .filter((key) => key !== "payload")
          .map((key) => {
            const value = obj[key];
            return (
              <>
                <DT cols={6}>{_.startCase(key)}</DT>
                <DD cols={6}>{typeof value === "object" ? MapObject(value) : value}</DD>
              </>
            );
          })}
      </DL>
    </>
  );
};

const SubscriptionTimeline = ({ subId }) => {
  let dataFetcher = () => getSubscriptionTimeline(subId);

  let dataMapper = (data) => (data.timeline.length === 0 ? <div>No results</div> : data.timeline.map((event) => <div>{MapObject(event)}</div>));

  return <Timeline dataFetcher={dataFetcher} dataMapper={dataMapper} />;
};

class ArvatoRecurringSubscription extends React.Component {
  state = {
    dialog: {},
    loading: false,
    subscription: null,
    timeline: null,
  };

  fetchSubscription = async () => {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      this.setState({ loading: true });
      const [subscription, timeline] = await Promise.all([getSubscription(this.props.sub._id), getSubscriptionTimeline(this.props.sub._id)]);
      this.setState({ subscription, timeline, loading: false });
    }
  };

  componentDidMount() {
    this.fetchSubscription();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sub !== this.props.sub) {
      this.fetchSubscription();
    }
  }

  cancelSubscription = async () => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Cancelling subscription..");
    try {
      await cancelSubscription(this.props.sub._id);
      this.fetchSubscription();
      toast.success("Successfully cancelled subscription for Arvato");
    } catch (err) {
      toast.error("Something failed with Arvato");
    }
  };

  reactivateSubscription = async () => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Reactivating subscription..");
    try {
      await reactivateSubscription(this.props.sub._id);
      this.fetchSubscription();
      toast.success("Successfully reactivated subscription for Arvato");
    } catch (err) {
      toast.error("Something failed with Arvato");
    }
  };

  terminateSubscription = async () => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Cancelling subscription..");
    try {
      await terminateSubscription(this.props.sub._id);
      this.fetchSubscription();
      toast.success("Successfully terminated subscription for Arvato");
    } catch (err) {
      toast.error("Something failed with Arvato");
    }
  };

  confirmCancelSubscription = (e) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Cancel arvato subscription?";
    dialog.text = "Sure you want to cancel the arvato subscription?";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.cancelSubscription();
    this.setState({ dialog });
  };

  confirmReactivateSubscription = (e) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Reactivate arvato subscription?";
    dialog.text = "Sure you want to reactivate the arvato subscription?";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.reactivateSubscription();
    this.setState({ dialog });
  };

  confirmTerminateSubscription = (e, row) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Terminate arvato subscription?";
    dialog.text = "Sure you want to terminate the arvato subscription? (This action can not be undone!)";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.terminateSubscription();
    this.setState({ dialog });
  };

  closeModal = () => {
    this.setState({ dialog: { open: false } });
  };

  render() {
    const { loading, dialog, subscription } = this.state;
    if (loading || !subscription) {
      return (
        <>
          <Row>
            <Col>
              <h1>
                Arvato Subscription <SubscriptionTimeline subId={this.props.sub._id} />
              </h1>
            </Col>
          </Row>
          <Spinner animation="border" size="sm" />
        </>
      );
    }

    const { closeDetails } = subscription;
    const isCancelled = closeDetails.canceledByClient || closeDetails.canceledByBillingPartner || closeDetails.terminatedByClient;
    let isTerminated = closeDetails.terminatedByClient;
    let cancelledBy = "";
    if (closeDetails.canceledByClient || closeDetails.terminatedByClient) {
      cancelledBy += "Norwegian Lab ";
    } else if (closeDetails.canceledByBillingPartner) {
      cancelledBy += "Arvato";
    }

    return (
      <React.Fragment>
        <Dialog open={dialog.open} doNo={this.closeModal} doYes={dialog.yesAction} title={dialog.title} text={dialog.text} yes={dialog.yes} no={dialog.no} />
        <Row>
          <Col>
            <h1>
              Arvato Subscription <SubscriptionTimeline subId={this.props.sub._id} />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <DL>
              <DT>Cancelled</DT>
              <DD>{isCancelled ? "Yes" : "No"}</DD>
              <DT>Terminated</DT>
              <DD>{isTerminated ? "Yes" : "No"}</DD>
              <DT>Cancelled By</DT>
              <DD>{cancelledBy}</DD>
            </DL>
          </Col>
          <Col md={5}>
            <DL>
              <DT>Current Period</DT>
              <DD>
                {subscription.currentPeriod && (
                  <>
                    {Intl.DateTimeFormat("no-NO", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    }).format(new Date(subscription.currentPeriod.start))}{" "}
                    -{" "}
                    {Intl.DateTimeFormat("no-NO", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    }).format(new Date(subscription.currentPeriod.end))}
                  </>
                )}
              </DD>
              <DT>Next billing date</DT>
              <DD>
                {subscription.billingDetails &&
                  Intl.DateTimeFormat("no-NO", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  }).format(new Date(subscription.billingDetails.nextBillingDate))}
              </DD>
              <DT>Active</DT>
              <DD>
                {subscription.active && (
                  <>
                    {Intl.DateTimeFormat("no-NO", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    }).format(new Date(subscription.active.from))}{" "}
                    -{" "}
                    {Intl.DateTimeFormat("no-NO", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    }).format(new Date(subscription.active.until))}
                  </>
                )}
              </DD>
            </DL>
          </Col>
          <Col md={2}>
            {isCancelled && !isTerminated && (
              <Button className="action-button" variant="warning" onClick={(e) => this.confirmReactivateSubscription(e)}>
                Reactivate
              </Button>
            )}
            {!isCancelled && !isTerminated && (
              <Button className="action-button" variant="warning" onClick={(e) => this.confirmCancelSubscription(e)}>
                Cancel
              </Button>
            )}
            {!isTerminated && (
              <Button className="action-button" variant="danger" onClick={(e) => this.confirmTerminateSubscription(e)}>
                Terminate
              </Button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ArvatoRecurringSubscription;
