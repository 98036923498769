import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/shipments";

export async function getShipments() {
  const { data } = await http.get(apiEndpoint);
  return data;
}

export async function getShipmentBatches(item) {
  const { data } = await http.post(apiEndpoint + "/batches/search", item);
  return data;
}

export async function getTotalShipmentBatchesCount() {
  const { data } = await http.get(apiEndpoint + "/batches/totalcount");
  return data;
}

export async function buildShipmentDebt() {
  const { data } = await http.get(apiEndpoint + "/debt");
  return data;
}

export async function getShipmentDebt() {
  const { data } = await http.get(apiEndpoint + "/debt/get");
  return data;
}

export async function createFile(shipmentId) {
  const { data } = await http.get(apiEndpoint + "/file/" + shipmentId);
  return data;
}

export async function createFiles(shipmentBatchId) {
  const { data } = await http.get(apiEndpoint + "/files/" + shipmentBatchId);
  return data;
}

export async function sendToVisma(shipmentId) {
  const { data } = await http.get(apiEndpoint + "/visma/" + shipmentId);
  return data;
}

export async function createCustomers(shipmentId) {
  const { data } = await http.get(apiEndpoint + "/arvato/create/" + shipmentId);
  return data;
}

export async function createCustomersBatch(shipmentBatchId) {
  const { data } = await http.get(apiEndpoint + "/arvato/createbatch/" + shipmentBatchId);
  return data;
}

export async function sendInvoices(shipmentId) {
  const { data } = await http.get(apiEndpoint + "/arvato/invoices/" + shipmentId);
  return data;
}

export async function sendInvoicesBatch(shipmentBatchId) {
  const { data } = await http.get(apiEndpoint + "/arvato/invoicesbatch/" + shipmentBatchId);
  return data;
}

export async function getShipmentById(shipmentId) {
  const { data } = await http.get(apiEndpoint + "/" + shipmentId);
  return data;
}

export async function createShipment(item) {
  const { data } = await http.post(apiEndpoint, item);
  return data;
}

export async function createShipment2(item) {
  const { data } = await http.post(apiEndpoint + "/create", item);
  return data;
}

export async function getShipmentsCount(item) {
  const { data } = await http.post(apiEndpoint + "/count", item);
  return data;
}

export async function getShipmentsCount2(item) {
  const { data } = await http.post(apiEndpoint + "/count2", item);
  return data;
}

export async function captureOrders(batchId) {
  const { data } = await http.post(apiEndpoint + `/captureKlarna/${batchId}`);
  return data;
}

export async function createNewShipment({ series, country, shipmentDate, batchId }) {
  const { data } = await http.post(apiEndpoint + `/new`, {
    series,
    country,
    shipmentDate,
    batchId,
  });
  return data;
}
