import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/products";

export async function getByrdStock() {
  const { data } = await http.get(apiEndpoint + "/byrdStock");
  return data;
}
