import React from "react";
import PageTable from "./common/PageTable";
import { Row, Col, Table, Spinner } from "react-bootstrap";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import Dialog from "./common/Dialog";
import { paginate } from "../utils/paginate";
import _ from "lodash";
import { toast } from "react-toastify";
import auth from "../services/authService";
import { creditInvoice, getInvoices, getInvoiceTimeline } from "../services/arvatoRecurringService";
import Timeline from "./common/Timeline";
import { DL, DT, DD } from "./DefinitionList";

const MapObject = (obj) => {
  let keys = Object.keys(obj);

  keys.forEach((key) => {
    const value = obj[key];
    if (key === "payload") {
      obj = { ...obj, ...value };

      Object.keys(value).forEach((newKey) => keys.push(newKey));
    }
  });

  keys.forEach((key) => {
    if (key === "initiatedAt") {
      obj[key] = Intl.DateTimeFormat("no-NO", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(new Date(obj[key]));
    } else if (key === "recordedAt" || key === "creditedAt") {
      obj[key] = Intl.DateTimeFormat("no-NO", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(obj[key]));
    }
  });

  return (
    <>
      <DL>
        {keys
          .filter((key) => key !== "payload")
          .map((key) => {
            const value = obj[key];
            return (
              <>
                <DT cols={6}>{_.startCase(key)}</DT>
                <DD cols={6}>{typeof value === "object" ? MapObject(value) : value}</DD>
              </>
            );
          })}
      </DL>
    </>
  );
};

const InvoiceTimeline = ({ subId, invoiceId }) => {
  let dataFetcher = () => getInvoiceTimeline(subId, invoiceId);
  let dataMapper = (data) => (data.timeline.length === 0 ? <div>No results</div> : data.timeline.map((event) => <div>{MapObject(event)}</div>));

  return <Timeline dataFetcher={dataFetcher} dataMapper={dataMapper} />;
};

class ArvatoRecurringInvoices extends PageTable {
  state = {
    dialog: {},
    loading: false,
    data: [],
    filters: [],
    selectedFilter: "",
    filterName: "price",
    pageSize: 10,
    currentPage: 1,
    sortColumn: {},
    searchProperties: ["price", "ip"],
    searchQuery: "",
    showDelete: true,
  };

  fetchInvoices = async () => {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      this.setState({ loading: true });
      const invoices = await getInvoices(this.props.sub._id);
      invoices.forEach((invoice) => getInvoiceTimeline(this.props.sub._id, invoice.invoiceNumber));
      this.setState({ data: invoices, loading: false });
    }
  };

  componentDidMount() {
    this.fetchInvoices();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sub !== this.props.sub) {
      this.fetchInvoices();
    }
  }

  creditOrderArvato = async (row) => {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog });
    toast.info("Crediting invoice..");
    try {
      await creditInvoice(this.props.sub._id, row.invoiceNumber);
      this.fetchInvoices();
      toast.success("Successfully sent to Arvato");
    } catch (err) {
      toast.error("Something failed with Arvato");
    }
  };

  confirmCreditArvato = (e, row) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Send credit to Arvato?";
    dialog.text = "Sure you want to send a credit to Arvato for this order? (this action cannot be reverted)";
    dialog.yes = "Yes";
    dialog.no = "No";
    dialog.yesAction = () => this.creditOrderArvato(row);
    this.setState({ dialog });
  };

  closeModal = () => {
    this.setState({ dialog: { open: false } });
  };

  render() {
    const { data, pageSize, currentPage, searchQuery, searchProperties, selectedFilter, filterName, sortColumn, loading } = this.state;

    const { length: count } = data;
    const pageName = "Invoice";
    const headers = ["Invoice date", "Invoice number", "Price", "Period", "Credit"];

    if (count === 0) return this.renderNoData(pageName);

    let filtered = data;

    filtered = this.applyFilters(filtered, searchQuery, searchProperties, filterName, selectedFilter);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginated = paginate(sorted, currentPage, pageSize);

    return (
      <React.Fragment>
        <Dialog
          open={this.state.dialog.open}
          doNo={this.closeModal}
          doYes={this.state.dialog.yesAction}
          title={this.state.dialog.title}
          text={this.state.dialog.text}
          yes={this.state.dialog.yes}
          no={this.state.dialog.no}
        />
        <PageHeader pageName={pageName} sorted={sorted} count={count} />
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {headers.map((header, index) => {
                    return <th key={index}>{header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {paginated.map((row, index) => {
                  const isCredited = row.creditNotes && row.creditNotes.length > 0;
                  let creditDate;
                  if (isCredited) {
                    creditDate = Intl.DateTimeFormat("no-NO", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    }).format(new Date(row.creditNotes[0].createdAt));
                  }
                  return (
                    <tr key={index}>
                      <td>
                        {row.invoiceDate &&
                          Intl.DateTimeFormat("no-NO", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          }).format(new Date(row.invoiceDate))}
                      </td>
                      <td>
                        {row.invoiceNumber} <InvoiceTimeline subId={this.props.sub._id} invoiceId={row.invoiceNumber} />
                      </td>
                      <td>{row.total.grossAmount}</td>
                      <td>
                        {Intl.DateTimeFormat("no-NO", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }).format(new Date(row.period.start))}{" "}
                        -{" "}
                        {Intl.DateTimeFormat("no-NO", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }).format(new Date(row.period.end))}
                      </td>
                      <td>
                        {!isCredited && (
                          <button className="btn btn-danger" onClick={(e) => this.confirmCreditArvato(e, row)}>
                            Credit
                          </button>
                        )}
                        {isCredited && <span>Credited {creditDate}</span>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination itemsCount={sorted.length} pageSize={pageSize} currentPage={currentPage} onPageChange={this.handlePageChange} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ArvatoRecurringInvoices;
