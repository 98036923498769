import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/trustpilot";

export async function sendInvitation(item) {
  const { data } = await http.post(apiEndpoint, item);
  return data;
}
