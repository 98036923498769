import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getProductSeries } from "../services/productService";

import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { createNewShipment } from "../services/shipmentService";
import { toast } from "react-toastify";

const initialTypes = ["initial", "regular"];
const dateToString = (date) => date.toISOString().split("T")[0];

const NewShipment = (props) => {
  const [country, setCountry] = useState("no");
  const [productSeries, setProductSeries] = useState([]);
  const [types, setTypes] = useState({});
  const [productSeriesList, setProductSeriesList] = useState([]);
  const [shipmentDate, setShipmentDate] = useState(dateToString(new Date()));
  const [busy, setBusy] = useState(false);
  const [batchId, setBatchId] = useState("");

  useEffect(() => {
    updateProductSeriesList(country);
  }, []);

  const updateProductSeriesList = async (country) => {
    let productSeriesList = await getProductSeries(country);
    setProductSeriesList(productSeriesList);
    changeProductSeriesSelected(productSeriesList);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    updateProductSeriesList(e.target.value);
  };

  const changeProductSeriesSelected = (newValues) => {
    const newTypes = types;
    newValues.forEach((value) => {
      newTypes[value] = initialTypes;
    });

    Object.keys(types).forEach((value) => {
      if (!newValues.includes(value)) {
        delete newTypes[value];
      }
    });
    setTypes(newTypes);
    setProductSeries(newValues);
  };

  const handleProductSeriesChange = (e) => {
    const options = Array.from(e.target.options);
    let newValues = options.filter((option) => option.selected).map((option) => option.value);
    changeProductSeriesSelected(newValues);
  };

  const handleChangeTypes = (e, series) => {
    const options = Array.from(e.target.options);
    setTypes({ ...types, [series]: options.filter((option) => option.selected).map((option) => option.value) });
  };

  const initiateShipment = async (e) => {
    let payload = {
      series: productSeries.map((series) => {
        return {
          series: series,
          types: types[series],
        };
      }),
      shipmentDate: new Date(shipmentDate),
      country: country,
      batchId: batchId,
    };
    console.log("payload", payload);
    try {
      setBusy(true);
      await createNewShipment(payload);
      toast.success("Sucessfully queued shipment");
    } catch (e) {
      toast.error("Something failed while queueing shipment");
    } finally {
      setBusy(false);
    }
  };

  return (
    <form onSubmit={initiateShipment}>
      <Row>
        <Col className="mb-3 mt-3">
          <h1>Creating new shipment</h1>
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <Row>
            <Col md={4}>
              <h5>Country</h5>
              <select onChange={handleCountryChange} value={country} style={{ width: "100%", marginBottom: 16 }}>
                <option value="no">Norway</option>
                <option value="se">Sweden</option>
                <option value="dk">Denmark</option>
                <option value="fi">Finland</option>
                <option value="de">Germany</option>
                <option value="nl">Netherlands</option>
                <option value="at">Austria</option>
                <option value="be">Belgium</option>
                <option value="pl">Poland</option>
              </select>
            </Col>
            <Col md={8}>
              <h5>Shipmentdate</h5>
              <input
                className="date"
                type="date"
                name="date"
                value={shipmentDate}
                onChange={(e) => setShipmentDate(e.target.value)}
                min=""
                max=""
                style={{ width: "100%", marginBottom: 16 }}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h5>Batch id</h5>
              <input type="text" name="batchId" value={batchId} onChange={(e) => setBatchId(e.target.value)} style={{ width: "100%", marginBottom: 16 }}></input>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h5>Product series</h5>
              <select onChange={handleProductSeriesChange} value={productSeries} multiple size={productSeriesList.length} style={{ width: "100%", marginBottom: 16 }}>
                {productSeriesList.map((productSeries, index) => (
                  <option key={productSeries} value={productSeries}>
                    {productSeries}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
        </Col>
        <Col md={8} sm={12}>
          <Row>
            {productSeries.map((series) => {
              return (
                <Col lg={3} key={series}>
                  <h6>{series}</h6>
                  <select onChange={(e) => handleChangeTypes(e, series)} size={types.length} multiple style={{ width: "100%", marginBottom: 16 }} value={types[series]}>
                    <option value="initial">Initial</option>
                    <option value="regular">Regular</option>
                  </select>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={{ offset: 10, span: 2 }}>
          <Button onClick={initiateShipment} disabled={busy}>
            Initiate Shipment
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default NewShipment;
