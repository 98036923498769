import http from "./httpService";
import axios from "axios";
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/articles";

async function getImageObj(article) {
  if (article.image) {
    // Convert the File object to a base64 data URL
    const dataURL = await fileToBase64(article.image);

    // Extract only the raw base64 part from the data URL
    const imageBase64 = dataURL.split(",")[1];

    console.log("article.image");
    return {
      data: imageBase64,
      mime: article.image.type,
      filename: article.image.name,
    };
  }

  return null;
}

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

async function getAllProducts() {
  const { data } = await http.get(apiEndpoint + "/products");
  return data;
}

async function createArticle(article) {
  let articleObject = await getArticleWithImage(article);

  const { data } = await http.post(apiEndpoint, articleObject);
  return data;
}

async function getArticleWithImage(article) {
  const imageObj = await getImageObj(article);

  let articleObject = article;
  if (imageObj) {
    articleObject = { ...article, image: imageObj };
  }
  return articleObject;
}

async function getArticles() {
  const { data } = await http.get(apiEndpoint);
  return data;
}

async function deleteArticle(id) {
  try {
    const response = await axios.delete(`${apiEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting article: ", error);
    throw error;
  }
}

async function getArticleById(id) {
  try {
    const response = await axios.get(`${apiEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Failed to get article by ID:", error);
    throw error;
  }
}

async function updateArticle(id, article) {
  try {
    let articleObject = await getArticleWithImage(article);
    const response = await http.put(`${apiEndpoint}/${id}`, articleObject);
    return response.data;
  } catch (error) {
    console.error("Failed to update article:", error);
    throw error;
  }
}
async function approveArticle(id) {
  try {
    const { data } = await http.patch(`${apiEndpoint}/${id}/approve`);
    return data;
  } catch (error) {
    console.error("Failed to approve article:", error);
    throw error;
  }
}
const approveArticleService = async (id) => {
  try {
    const response = await fetch(`/api/articles/approve/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error approving article:", error);
  }
};

export { createArticle, getArticles, deleteArticle, getArticleById, updateArticle, getAllProducts, approveArticle, approveArticleService };
