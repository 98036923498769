import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/blacklist";

export async function getBlacklist(item) {
  const { data } = await http.post(apiEndpoint, item);
  return data;
}

export async function countBlacklist() {
  const { data } = await http.get(apiEndpoint + "/count");
  return data;
}

export async function deleteItem(id) {
  const { data } = await http.post(apiEndpoint + "/delete", { id });
  return data;
}

export async function addItem(item) {
  const { data } = await http.post(apiEndpoint + "/create", item);
  return data;
}
