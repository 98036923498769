import React from "react";
import authService from "../../services/authService";

const Auth = (props) => {
  const authorized = authService.hasRole(props.roles);
  if (!authorized) return null;
  return props.children;
};

export default Auth;
