import React, { Component } from "react";

class SearchBox extends Component {
  handleEnterPress = (e) => {
    if (e.key === "Enter") this.props.onSearchSubmit(1, e.currentTarget.value, this.props.selectedFilter);
  };

  handleResetSearch = () => {
    this.props.onSearchSubmit(1, "");
  };

  render() {
    const { value, onChange, onReset } = this.props;
    return (
      <div className="search-box">
        <input
          className="form-control"
          type="text"
          placeholder="Search..."
          onKeyPress={(e) => this.handleEnterPress(e)}
          onChange={(e) => onChange(e.currentTarget.value)}
          value={value}
        />
        <i className="fas fa-times fa-2x pointer" onClick={this.handleResetSearch}></i>
      </div>
    );
  }
}

export default SearchBox;
