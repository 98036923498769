import React, { useCallback, useEffect, useState } from "react";
import { Container, Grid, Button } from "@mui/material";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getInbox, postStatus } from "../../services/inboxService";

/* type EMAIL_CATEGORY_TYPE = "UNSUBSCRIBE" | "RETURN" | "DID_NOT_ORDER"; */

const Ul = styled.ul`
  list-style: none;
`;
const Li = styled.li`
  padding: 1.5rem;
  background: ${(props) => (props.isActive ? "#525050" : "grey")};
  border-bottom: 1px solid black;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
`;

const DetailViewContainer = styled.div`
  padding: 1rem;
  margin-left: 1rem;
  border: 1px solid grey;
  border-radius: 10px;

  h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-style: italic;
  }
`;

const TemplateEmail = styled.div`
  background: #525050;
  padding: 1rem;
  border-radius: 10px;
`;

const Category = styled.span`
  padding: 0.4rem;
  border-radius: 5px;
  background-color: ${(props) => {
    switch (props.category) {
      case "UNSUBSCRIBE":
        return "#9d7373";
      case "RETURN":
        return "#565681";
      case "DID_NOT_ORDER":
        return "#5a535a";
      default:
        return "grey";
    }
  }};
`;

const ListView = ({ items, onSelectItem, selectedItem }) => {
  //if (!selectedItem) return <div>No selected item</div>;
  return (
    <Ul>
      {items.map((item) => (
        <Li
          key={item._id}
          onClick={() => onSelectItem(item)}
          isActive={item._id === selectedItem?._id} // Pass isActive prop based on the current selection
        >
          <div>
            <h5>
              <b>{item.subject}</b>
            </h5>
            <TemplateMessage>{item.message}</TemplateMessage>
            <Category category={item.category}>{item.category}</Category>
          </div>
        </Li>
      ))}
    </Ul>
  );
};

const EmailInfo = ({ label, value }) => (
  <div style={{ padding: "0.2rem", color: "white" }}>
    <label style={{ color: "white" }}>{label}: </label> <span>{value}</span>
  </div>
);

const TemplateMessage = styled.pre`
  border: 1px solid grey;
  border-radius: 10px;
  padding: 1rem;
  background: #676664;
  color: white;
`;

const SuggestedResponseContainer = styled.div`
  padding: 1rem;
  border: 1px solid grey;
  margin-top: 0.5rem;
  border-radius: 10px;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  margin-top: 1rem;
`;
const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 40px;
`;

const SuggestedResponseTextContainer = styled.div``;

const ResponseEmail = ({ category, name }) => {
  const Response = () => {
    switch (category) {
      case "UNSUBSCRIBE":
        return "Customer should be unsubscribed from the sub above";
      case "RETURN":
      case "DID_NOT_ORDER":
      default:
        return <p>No response available for this category.</p>;
    }
  };

  return <Response />;
};

const CustomerInfo = ({ customerData: { customer, sub } }) => {
  console.log(customer);
  return (
    <div>
      <dl>
        <dt>Customer</dt>
        <dd>{customer && <a href={`/customers/${customer._id}`}>{customer.name}</a>}</dd>
        <dt>Sub</dt>
        <dd>{sub && <a href={`/customers/${customer._id}/subs/${sub._id}`}>{sub.product.name}</a>}</dd>
      </dl>
    </div>
  );
};

const SuggestedResponse = ({ item }) => {
  if (!item) return <>No item</>;

  return (
    <SuggestedResponseContainer>
      <CustomerInfo customerData={item} />
      <hr style={{ borderInlineEndColor: "white" }} />
      <SuggestedResponseTextContainer>
        <ResponseEmail category={item.category} name={item.customer?.name} />
      </SuggestedResponseTextContainer>
    </SuggestedResponseContainer>
  );
};

const DetailView = ({ item, onStatusChange }) => {
  if (!item) return <>No item</>;
  return (
    <DetailViewContainer>
      <TemplateEmail>
        <EmailInfo label="Customer" value={`${item.customer?.name}`} />
        <EmailInfo label="From" value={item.from} />
        <EmailInfo label="Subject" value={item.subject} />
        <EmailInfo label="Category" value={item.category} />
        <TemplateMessage>{item.message}</TemplateMessage>
      </TemplateEmail>
      <h2>Suggested Actions</h2>
      <SuggestedResponse item={item}></SuggestedResponse>
      <ButtonContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            onStatusChange(item, "APPROVED");
          }}
        >
          Approve
        </Button>
        <Button variant="contained" color="secondary" onClick={() => onStatusChange(item, "REJECTED")}>
          Reject
        </Button>
      </ButtonContainer>
    </DetailViewContainer>
  );
};

const fetchData = async (onResult) => {
  try {
    const inboxData = await getInbox();

    const smallerList = inboxData;
    onResult(smallerList);
    // Set the first item as selected by default
  } catch (error) {
    console.error("There was an error fetching the inbox data:", error);
  }
};

async function postStatusWith(id, status) {
  await postStatus(id, status);
}

const getFiltered = (list, status) => {
  if (status === "ALL") return list;
  return list.filter((item) => item.status === status);
};

const filterValues = ["NEW", "ALL", "APPROVED", "REJECTED"];

const Inbox = () => {
  const [items, setItems] = useState([]); // Initialized with data from InboxList.json
  const [selectedItem, setSelectedItem] = useState(items.length > 0 ? items[0] : null);
  const [activeTab, setActiveTab] = useState(0); // For tabs
  const [filteredItems, setFilteredItems] = useState(items); // Filtered items based on the current filter

  const loadData = useCallback(() => {
    fetchData((data) => {
      setItems(data); // Update state with fetched data
      const filtered = getFiltered(data, filterValues[activeTab]); // Filter on aactive tab
      setFilteredItems(filtered);
      setSelectedItem(filtered[0]);
    });
  }, [activeTab]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {}, [filteredItems]);
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };

  // Dynamically calculating the counts for each category

  const newCount = getFiltered(items, "NEW").length;
  const totalCount = items.length;
  const approvedCount = getFiltered(items, "APPROVED").length;
  const rejectedCount = getFiltered(items, "REJECTED").length;
  // Handle status change

  const onStatusChange = async (item, newStatus) => {
    await postStatusWith(item._id, newStatus);
    loadData();
  };

  return (
    <>
      <Container maxWidth={false} style={{ padding: "2rem" }}>
        <FilterContainer>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => {
              setActiveTab(newValue);
              const activatedFilter = filterValues[newValue];
              if (newValue === 1) {
                // Edge case
                setFilteredItems(items);
              } else {
                const filtered = getFiltered(items, activatedFilter);
                setFilteredItems(filtered);
              }
            }}
            aria-label="filter tabs"
          >
            <Tab label={`New (${newCount})`} />
            <Tab label={`All (${totalCount})`} />
            <Tab label={`Approved (${approvedCount})`} />
            <Tab label={`Rejected (${rejectedCount})`} />
          </Tabs>
        </FilterContainer>
        <Grid container>
          <Grid item xs={6}>
            <ListView items={filteredItems} onSelectItem={handleSelectItem} selectedItem={selectedItem} />
          </Grid>
          <Grid item xs={6}>
            <DetailView item={selectedItem} onStatusChange={onStatusChange} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Inbox;
