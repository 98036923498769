import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const inboxEndpoint = env.REACT_APP_API + "api/inbox";

export async function getInbox() {
  const { data } = await http.get(inboxEndpoint);
  return data;
}

export async function postStatus(id, status) {
  const url = `${inboxEndpoint}/${id}/${status}`;

  try {
    const response = await http.post(url);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json(); // Assuming server responds with JSON
    console.log("data in response postStatus"); // Handle the response data as needed
    console.log(data); // Handle the response data as needed
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
}
