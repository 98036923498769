import React from "react";
import date from "date-and-time";
import { Row, Col } from "react-bootstrap";

function Smslogs({ smslogs }) {
  return (
    <>
      <Row>
        <Col className="mb-3 mt-3">
          <h1>Sms log</h1>
        </Col>
      </Row>
      <div className="comments-wrapper">
        {smslogs &&
          smslogs.map((sms, index) => {
            return (
              <div className="comment" key={index}>
                <div className="comment-date">{date.format(new Date(sms.created), "DD.MM.YYYY")}</div>
                <div className="comment-text">{sms.message}</div>
                <div className="comment-user">Status: {sms.status}</div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default Smslogs;
