const lightTheme = {
  background: "#ffffff", // White background for light theme
  textColor: "#000000", // Black text for light theme
  navbarBackground: "#f8f9fa", // Light gray for Bootstrap default navbar
  // ... other colors
};
const darkTheme = {
  background: "#343a40", // Dark gray background for dark theme
  textColor: "#ffffff", // White text for dark theme
  navbarBackground: "#212529", // Darker shade for navbar
  // ... other colors
};

export { lightTheme, darkTheme };
