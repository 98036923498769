import React from "react";
import Form from "./common/Form";
import { Container, Row, Col } from "react-bootstrap";
import Joi from "joi-browser";
import { getOrderById, updateOrder, createOrder } from "../services/orderService";
import { getSubById } from "../services/subService";
import { toast } from "react-toastify";
import auth from "../services/authService";
import date from "date-and-time";

class EditOrder extends Form {
  state = {
    data: {
      nextDelivery: "",
      price: 0,
      discount: 0,
      shippingPrice: 0,
      antall: 0,
      type: "",
      shippingCode: "",
      seq: 0,
    },
    order: {},
    errors: {},
  };

  schema = {
    nextDelivery: Joi.date().label("Next Delivery").allow(""),
    price: Joi.number().label("Price").required(),
    discount: Joi.number().label("Discount").required(),
    shippingPrice: Joi.number().label("Shipping Price").required(),
    antall: Joi.number().label("Amount").required(),
    type: Joi.string().label("Type").required(),
    shippingCode: Joi.string().label("Shipping Code").required(),
    seq: Joi.number().label("Sequence").required(),
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      let { data, order } = { ...this.state };

      if (this.props.match.params.orderid === "new") {
      } else {
        order = await getOrderById(this.props.match.params.orderid);

        data.type = order.type;
        data.price = order.price;
        data.discount = order.discount;
        data.antall = order.antall;
        data.shippingCode = order.shippingCode;
        data.seq = order.seq;

        let nextDeliveryDate;
        if (order.nextDelivery) nextDeliveryDate = date.format(new Date(order.nextDelivery), "YYYY-MM-DD");
        else nextDeliveryDate = "";

        data.nextDelivery = nextDeliveryDate;
      }

      this.setState({ data, order });
    }
  }

  doTypeChange = async (data) => {
    const sub = await getSubById(this.props.match.params.subid);
    let product = {};
    if (sub && sub.product) product = sub.product;

    if (data.type === "initial") {
      data.price = product.regularPrice * product.months;
      data.discount = product.regularPrice * product.months;
      data.shippingPrice = product.initialPrice;
      data.antall = product.packs;
      data.shippingCode = product.shippingCode;
    } else if (data.type === "regular") {
      data.price = product.regularPrice * product.regularMonths;
      data.discount = 0;
      data.shippingPrice = 0;
      data.antall = product.regularPacks;
      data.shippingCode = product.regularShippingCode;
      data.seq = 1;
    } else if (data.type === "holdback") {
      data.price = product.regularPrice * product.regularMonths;
      data.discount = product.regularPrice * product.regularMonths * 0.5;
      data.shippingPrice = 0;
    }

    this.setState({ data });
  };

  doSubmit = async () => {
    let { data, errors } = this.state;
    const orderId = this.props.match.params.orderid;

    if (data.discount && data.price && (data.discount > data.price || data.discount < 0)) toast.error("Discount cannot be lower than Price and lower than 0.");
    else {
      if (orderId === "new") {
        try {
          data.subId = this.props.match.params.subid;
          await createOrder(data);
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            errors.name = ex.response.data;
            this.setState({ errors });
          }
        }
      } else {
        try {
          data._id = orderId;
          await updateOrder(data);
          delete data._id;
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            errors.name = ex.response.data;
            this.setState({ errors });
          }
        }
      }
      toast.success("Saved order");
    }
  };

  handleBack = (e) => {
    e.preventDefault();
    if (this.state.order.type === "single") {
      this.props.history.push("/customers/" + this.props.match.params.customerid);
    } else {
      this.props.history.push("/customers/" + this.props.match.params.customerid + "/subs/" + this.props.match.params.subid);
    }
  };

  render() {
    return (
      <Container>
        <Row>
          <Col className="mb-3 mt-3">
            <h1>Editing order</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              Created:{" "}
              {this.state.order.created &&
                Intl.DateTimeFormat("no-NO", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                }).format(new Date(this.state.order.created))}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              Updated:{" "}
              {this.state.order.updated &&
                Intl.DateTimeFormat("no-NO", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                }).format(new Date(this.state.order.updated))}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <form onSubmit={this.handleSubmit}>
              <div className="mb-3">
                {this.renderButton("Save")}
                <button className="btn btn-secondary ml-3" onClick={this.handleBack}>
                  Back
                </button>
              </div>
              {this.renderDropdown("type", "Type", ["", "initial", "regular", "holdback", "custom", "return", "single"])}
              {this.renderInput("seq", "Sequence")}
              {this.renderInput("nextDelivery", "Next Delivery", "date")}
              {this.renderInput("price", "Price", "text", this.state.data.type !== "custom" && this.state.data.type !== "return")}
              {this.renderInput("discount", "Discount", "text", this.state.data.type !== "custom" && this.state.data.type !== "return")}
              {this.renderInput("shippingPrice", "Shipping Price", "text", this.state.data.type !== "custom" && this.state.data.type !== "return")}
              {this.renderInput("antall", "Months", "text", this.state.data.type !== "custom" && this.state.data.type !== "return")}
              {this.renderInput("shippingCode", "Shipping Code", "text", this.state.data.type !== "custom" && this.state.data.type !== "return")}
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditOrder;
