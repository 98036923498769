import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/hues";

export async function getTestlist(item) {
  const { data } = await http.post(apiEndpoint + "/list", item);
  return data;
}

export async function countTestlist() {
  const { data } = await http.get(apiEndpoint + "/count");
  return data;
}
