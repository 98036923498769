import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const jobsEndpoint = env.REACT_APP_JOBS_API;
const backendEndpoint = env.REACT_APP_SKINGLOW_API;

export async function createShipmentJob(item) {
  const { data } = await http.post(jobsEndpoint + "/shipment", item);
  return data;
}

export async function createFileJob(item) {
  const { data } = await http.post(jobsEndpoint + "/file", item);
  return data;
}

export async function createSmsBatch(reminder) {
  const { data } = await http.post(jobsEndpoint + "/sms", {
    type: reminder.type
  });
  return data;
}

export async function getSmsBatches() {
  const { data } = await http.get(backendEndpoint + "/sms/batches/");
  return data;
}

export async function deleteSmsJob(jobId) {
  const { data } = await http.delete(
    backendEndpoint + "/sms/batches/delete/" + jobId
  );
  return data;
}
