import React from "react";
import PageTable from "./common/PageTable";
import { Row, Col, Table } from "react-bootstrap";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import { getTestlist, countTestlist } from "../services/testlistService";
import _ from "lodash";
import { toast } from "react-toastify";
import authService from "../services/authService";
import date from "date-and-time";

class Testsales extends PageTable {
  state = {
    data: [],
    dataLength: 0,
    totalLength: 0,
    pageSize: 20,
    currentPage: 1,
    searchQuery: "",
    loading: true,
    phrase: "",
  };

  async componentDidMount() {
    if (authService.hasRole(["admin"])) {
      // FIX HUESdata
      const { data } = await getTestlist({ page: this.state.currentPage, pagination: this.state.pageSize, phrase: this.state.phrase });
      const { length } = await countTestlist();

      this.setState({
        totalLength: length,
        data,
        dataLength: length,
        loading: false,
      });
    }
  }

  handleSearchChange = (searchQuery) => {
    this.setState({
      searchQuery,
    });
  };

  handleSearchSubmit = async (page, phrase) => {
    if (authService.hasRole(["admin"])) {
      const { data } = await getTestlist({ page, pagination: this.state.pageSize, phrase: phrase });
      const { length } = await countTestlist();
      if (phrase)
        this.setState({
          data,
          dataLength: data.length,
          searchQuery: phrase,
        });
      else
        this.setState({
          data,
          dataLength: length,
          searchQuery: "",
        });
    }
  };

  handlePageChangeQuery = async (page, phrase) => {
    if (authService.hasRole(["admin"])) {
      this.setState({ loading: true });

      const { data } = await getTestlist({ page, pagination: this.state.pageSize, phrase });

      this.setState({
        phrase,
        currentPage: page,
        data,
        loading: false,
      });
    }
  };

  render() {
    const { data, pageSize, currentPage, phrase, loading, dataLength, totalLength, searchQuery } = this.state;

    const pageName = "Testsales";
    const headers = ["Created", "Title", "Description", "Price", "Phone", "First Name", "Last Name", "Address", "Postal Code", "City", "DoB", "Email"];

    return (
      <React.Fragment>
        <PageHeader
          hasSearch="true"
          onSearch={this.handleSearchChange}
          onSearchSubmit={this.handleSearchSubmit}
          searchQuery={searchQuery}
          pageName={pageName}
          sorted={data}
          count={dataLength}
          totalCount={totalLength}
          loading={loading}
          selectedFilter={null}
        />
        {!loading && (
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {headers.map((header, index) => {
                      return <th key={index}>{header}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>{date.format(new Date(row.created), "DD-MM-YYYYT HH:mm:ss")}</td>
                        <td>{row.title}</td>
                        <td>{row.description}</td>
                        <td>{row.price}</td>
                        <td>{row.phoneNumber}</td>
                        <td>{row.firstName}</td>
                        <td>{row.lastName}</td>
                        <td>{row.address}</td>
                        <td>{row.postalCode}</td>
                        <td>{row.city}</td>
                        <td>{row.day + "-" + row.month + "-" + row.year}</td>
                        <td>{row.email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination itemsCount={dataLength} pageSize={pageSize} currentPage={currentPage} phrase={phrase} onPageChange={this.handlePageChangeQuery} selectedFilter={null} />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default Testsales;
