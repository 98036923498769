import React from "react";
import { Row, Col } from "react-bootstrap";
import SearchBox from "./SearchBox";

const PageHeader = ({
  pageName,
  sorted,
  count,
  totalCount,
  searchQuery,
  onSearch,
  onSearchSubmit,
  onNew,
  hasSearch,
  selectedFilter,
  loading,
}) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1>{pageName}s</h1>
          {loading ? (
            <h3>Loading...</h3>
          ) : (
            <h3>
              Viewing {count} of {totalCount} {pageName.toLowerCase()}(s).
            </h3>
          )}
        </Col>
      </Row>
      {hasSearch && (
        <Row>
          <Col className="col-sm-8 col-lg-3 my-3">
            <SearchBox
              value={searchQuery}
              onChange={onSearch}
              onSearchSubmit={onSearchSubmit}
              selectedFilter={selectedFilter}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col className="mb-3">
          {onNew && (
            <button className="btn btn-primary" onClick={onNew}>
              New {pageName.toLowerCase()}
            </button>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PageHeader;
