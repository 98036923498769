import { createContext } from "react";

export const SearchContext = createContext(null);

export const SearchDispatchContext = createContext(null);

export function searchReducer(filters, action) {
  switch (action.type) {
    case "change": {
      return { ...filters, [action.filter]: action.value };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
