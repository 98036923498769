import http from "./httpService";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_SKINGLOW_API + "/subs";

export async function getSubs() {
  const { data } = await http.get(apiEndpoint);
  return data;
}

export async function getSubById(subId) {
  const { data } = await http.get(apiEndpoint + "/" + subId);
  return data;
}

export async function updateSub(item) {
  const { data } = await http.put(apiEndpoint, item);
  return data;
}

export async function createSub(item, customer) {
  const { data } = await http.post(apiEndpoint, { sub: item, customer });
  return data;
}
